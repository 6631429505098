import Vue from 'vue'
import main from './index.vue'
// import store from '@/store'
const MyMessage = Vue.extend(main)
const instance = {}
// let instances=[]
// let seed=1
const Message = function(options, color, icon, typeClass) {
  options = options || {}
  if (typeof options === 'string') {
    options = {
      message: options,
      color: color,
      icon: icon,
      typeClass: typeClass
    }
  }
  instance.vm = new MyMessage({
    data: options
  }).$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el
  instance.dom.style.zIndex = '1000'
  // list
  // instances.push(instance)
  // instances.push(instance.vm)
  // console.log(store)
  // store.state.messageList.push(options)
  return instance.vm
}
// #67c23a 2196f3
Message.success = function(option) {
  Message(option, '#4CAF50', 'beenhere', 'message-success')
}
Message.error = function(option) {
  Message(option, '#F44336', 'beenhere', 'message-error')
}
// Message.close=function(){
//     for(let i =0,len){

//     }
// }
export default Message
