<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true,
      beforeUnload: '',
      Handler: ''
    }
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
    console.log('this is app.vue')
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    beforeunloadHandler(e) {
      this.beforeUnload = new Date().getTime()
      return ''
    },
    unloadHandler(e) {
      this.Handler = new Date().getTime() - this.beforeUnload
      // 判断是窗口关闭还是刷新
      if (this.Handler <= 5 && localStorage.getItem('access_token')) {
        const data = { token: localStorage.getItem('access_token'), scene: 2 }
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
          let value = data[key]
          if (typeof value !== 'string') {
          // formData只能append string 或 Blob
            value = JSON.stringify(value)
          }
          formData.append(key, value)
        })
        localStorage.removeItem('access_token')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('writingPlanId')
        navigator.sendBeacon('ctms/sys/beaconLogout', formData)
      }
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
