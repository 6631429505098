<template>
  <div @click="!exportBtnLoading?exportList():''">
    <slot>
      <!-- color=" primary" -->
      <v-btn width="32px" min-width="34" height="30" title="导出" depressed :loading="exportBtnLoading" class=" primary--text px-0">
        <v-icon size="14">
          mdi-export
        </v-icon>
      </v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    parmas: {
      type: [Array, Object],
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    frontName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      exportBtnLoading: false
    }
  },
  methods: {
    exportList() {
      const _this = this
      _this.exportBtnLoading = true
      const search = { ...this.parmas }
      const param = new URLSearchParams()
      for (const i in search) {
        param.append(i, (search[i] || (search[i] === 0)) ? search[i] : '')
      }
      _this.$http.blobGet(this.url, { data: param }).then((_r) => {
        const res = _r.data
        const r = new FileReader()
        r.onload = function() {
          try {
            JSON.parse(this.result)
            if ([406, 407, 408].includes(parseInt(JSON.parse(this.result).code))) {
              localStorage.removeItem('access_token')
              localStorage.removeItem('userInfo')
              _this.$message.error(JSON.parse(this.result).message || '请稍后重试')
              _this.$router.replace({ path: '/login' })
            } else {
              _this.$message.error('下载失败！')
            }
          } catch (err) {
            const _res = res
            // const blob = new Blob([_res])
            const blob = new Blob([_res], { type: 'application/octet-stream' })
            const href = window.URL.createObjectURL(blob)
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            downloadElement.href = href
            // downloadElement.download = _this.name || (_this.frontName + _r.headers['content-disposition'].slice(_r.headers['content-disposition'].indexOf('filename=') + 9)) // 下载后文件名
            downloadElement.download = _this.name || (_this.frontName + decodeURI(_r.headers['content-disposition'].slice(_r.headers['content-disposition'].indexOf('filename=') + 9))) // 下载后文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() // 点击下载
            document.body.removeChild(downloadElement) // 下载完成移除元素
            window.URL.revokeObjectURL(href) // 释放掉blob对象
            _this.$forceUpdate()
            _this.$message.success('下载完成！')
          }
        }
        r.readAsText(res)
        _this.exportBtnLoading = false
      }).catch(() => {
        _this.exportBtnLoading = false
      })
    }
  }
}
</script>

<style>

</style>
