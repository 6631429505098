/*
 * @Description: 增加antd ui 组件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2020-07-07 23:12:33
 */

// 导入ant组件
import "./core/components_use";
import * as all from "./mini";

export default all.default;
export let setFormDesignConfig = all.setFormDesignConfig;
export let KFormDesign = all.KFormDesign;
export let KFormPreview = all.KFormPreview;
export let KFormBuild = all.KFormBuild;
export let KFormItem = all.KFormItem;
