<template>
  <!-- 列表排序 -->
  <v-dialog v-model="dialogShow" width="1000" :content-class="dialogShow?'flex-column overflow-hidden':''">
    <v-card class=" flex-1 flex-column overflow-hidden">
      <v-card-title class=" flex-0 font-weight-bold">拖拽排序</v-card-title>
      <v-divider />
      <v-card-text class=" flex-1 py-5 overflow-auto grey--text text--darken-3">
        <draggable v-model="list" element="div" scroll animation="300" :scroll-sensitivity="150" :force-fallback="true" ghost-class="ghost" drag-class="drag" class="px-2 pt-2" style="min-height:200px;">
          <v-row v-for="(item,index) in list" :key="index" draggable="true" class=" mb-1" style="background:#f1f1f1;border:1px solid #ddd">
            <v-col sm="1" md="2" class=" py-2">序号：{{ index+1 }}</v-col>
            <slot name="content" :item="item" />
          </v-row>
        </draggable>
      </v-card-text>
      <v-divider />
      <v-card-actions class=" flex-0">
        <div class="max-width flex-y-center flex-justify-between">
          <div class="flex-1 flex-justify-end">
            <v-btn width="80" class depressed @click="dialogShow = false">取消</v-btn>
            <v-btn
              :disabled="!list.length"
              width="80"
              :loading="btnLoading"
              type="submit"
              class="ml-3"
              color="primary"
              depressed
              @click="sortFields"
            >确定</v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    api: {
      type: String,
      default: ''
    },
    params: {
      type: [Array, Object],
      default: () => {}
    }
  },
  data() {
    return {
      dialogShow: false,
      list: [],
      listLoading: false,
      btnLoading: false
    }
  },
  methods: {
    show() {
      this.list = []
      this.dialogShow = true
      this.getList()
    },
    // 获取列表
    getList() {
      this.listLoading = true
      const params = { ...this.params } || {}
      this.$http.get(this.api, { data: { ...params }}).then(res => {
        this.list = res.result.records || []
        this.total = res.result.total || 0
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 确认排序
    sortFields() {
      this.btnLoading = true
      const params = { ...this.params } || {}
      let ids = []
      ids = this.list.map(_t => {
        return _t.id
      })
      params.ids = ids.join(',')
      this.$http.post(this.$api.customFieldSortFields, { data: { ...params }, type: 'application/x-www-form-urlencoded' }).then(res => {
        this.dialogShow = false
        this.btnLoading = false
        this.$emit('loadList')
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style>
.ghost{
  background: #ddd !important;
  border-color: #2196f3 !important;
}
.drag{
  background: #f1f1f1 !important;
  border-color: #2196f3 !important;
}
</style>
