import Vue from 'vue'
import Confirm from './index.vue'
const VueComponent = Vue.extend(Confirm)
const vm = new VueComponent().$mount()

let init = false
const defaultOptions = {
}
const confirm = function(options) {
  Object.assign(vm, defaultOptions, options, {
    type: 'confirm'
  })
  if (!init) {
    document.body.appendChild(vm.$el)
    init = true
  }
  return vm.confirm(options)
}
export default confirm
