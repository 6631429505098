<template>
  <div
    v-if="pageMax>0"
    :class="hideExplain?'flex-x-center':'flex-justify-between'"
    class=" flex-y-center px-3 "
    style="position: relative;padding:2px 0"
  >
    <!-- v-if="pageMax>1" -->

    <div v-if="!hideExplain" class="body-2 flex-shrink-0 grey--text">共计 {{ total }} 条记录，当前第<span
      class="mx-1"
    >{{ page===1?'1':(page-1)*size+1 }}</span>条到第<span class="mx-1">
      <!-- {{pageMax===1?total:page*size}} -->
      {{ (page*size)>total?total:(page*size) }}
    </span>条。
    </div>
    <!-- v-if="pageMax>1" -->
    <!-- <div class="flex-grow-1 " :class="{'flex-justify-end':!hideExplain}" v-if="pageMax>0"> -->
    <!-- {{page}} -->
    <v-pagination
      v-if="pageMax>0"
      v-model="page"
      :length="pageMax"
      :total-visible="7"
      sm
    />
    <!-- </div> -->
    <div>
      <v-menu
        :close-on-content-click="false"
        bottom
        max-height="300"
        offset-overflow
        transition="slide-y-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <div class="default-input px-1 py-0" style="width:80px;" color="primary" v-on="on">
            {{ pageSize }}条/页

          </div>
        </template>
        <v-list class="pa-0 radius-0" subheader dense>
          <v-list-item v-for="(item,index) in pageLists" :key="index" @click="changePageSize(item)">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    hideExplain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: this.value,
      pageLists: [10, 20, 50, 100]
    }
  },
  computed: {
    pageMax() {
      const TotalRecord = parseInt(this.total)
      const pageSize = this.size
      // console.log(parseInt(TotalRecord % pageSize === 0 ? (TotalRecord / pageSize) : (Math.floor(TotalRecord / pageSize) + 1)))
      return parseInt(TotalRecord % pageSize === 0 ? (TotalRecord / pageSize) : (Math.floor(TotalRecord / pageSize) + 1))
    }
  },
  watch: {
    value(newVal) {
      this.page = newVal
    },
    page(newVal) {
      this.$emit('input', newVal)
      // this.$emit('input', {pageSize:this.pageSize,page:newVal})
    }
    // pageSize(newVal) {
    //     console.log(newVal)
    //   //   console.log(typeof newVal)
    //   //   console.log(newVal.toString())
    //   //   this.$emit('input', newVal.toString())
    //   // this.$emit('input', {pageSize:newVal,page:this.page})
    // }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 改变显示的条数
    changePageSize(item) {
      this.pageSize = item
      this.$emit('changePageSize', this.pageSize)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>

