<template>
  <div :class="[direction,{'line':line,'dense':dense}]" class="form-item">
    <!--    :value="selectName"-->
    <!--    :style="{'line-height':type==='textarea'?'normal':'40px'}"-->
    <div v-if="label&&isShowLabel" :style="{'min-width':`${labelWidth}px`}" class="form-item-label">
      <span v-if="required" class="red--text mr-1">*</span>
      <div v-if="label" :class="{'white--text':dark}">{{ label }}：</div>
    </div>
    <slot>
      <template v-if="['H1','H2','H3','H4'].includes(type)">
        <span v-if="type=='H1'" style="font-size:20px;font-weight: 700!important;">{{ itemText }}</span>
        <span v-if="type=='H2'" style="font-size:18px;font-weight: 700!important;">{{ itemText }}</span>
        <span v-if="type=='H3'" style="font-size:16px;font-weight: 700!important;">{{ itemText }}</span>
        <span v-if="type=='H4'" style="font-size:14px;font-weight: 700!important;">{{ itemText }}</span>
      </template>
      <div v-else :class="[{'disabled':disabled},type]" class="form-item-input">
        <div v-if="prepend" class="prepend">{{ prepend }}</div>
        <template v-if="type==='select'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" :title="selectName" v-on="on">
                <input
                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :value="selectName"
                  readonly
                  type="text"
                >
                <div class=" flex-y-center">
                  <template v-if="allowClose&&!(disabled||readonly)">
                    <template v-if="multiple">
                      <v-icon v-if="myValue&&myValue.length" size="16" @click.stop="myValue=[],$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                    <template v-else>
                      <v-icon v-if="myValue" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                  </template>
                  <v-icon v-if="!readonly&&!disabled" size="20">mdi-chevron-down</v-icon>
                </div>
              </div>
            </template>
            <div ref="search" class="search" @scroll="scroll">
              <div v-if="options.length>10&&!noSelect" class="flex-center pa-3 sticky-top white">
                <input
                  v-model="search.input"
                  class="input"
                  placeholder="搜索..."
                  style="border: 1px solid #dcdcdc;"
                  type="text"
                >
              </div>
              <v-list v-if="search.list.length" class="pb-0" dense subheader>
                <v-list-item
                  v-for="(t,i) in search.list"
                  :key="i"
                  :ripple="false"
                  class="flex-y-center"
                  link
                >
                  <!--  -->
                  <v-checkbox
                    v-if="multiple"
                    :key="t[itemKey]"
                    v-model="myValue"
                    :ripple="false"
                    :value="t[itemKey]"
                    class="max-width mt-0 pt-0"
                    color="primary"
                    hide-details=""
                    @change="$emit('select', {t:t,val:myValue})"
                  >
                    <!-- <v-checkbox
                    :key="t.value"
                    :ripple="false"
                    :value="t.value"
                    class="max-width mt-0 pt-0"
                    color="primary"
                    hide-details=""
                    v-if="multiple"
                    v-model="myValue"
                    @change="$emit('select', t)"
                  > -->
                    <template v-slot:label>
                      <v-list-item-avatar v-if="t.img" class="ml-3 mr-1">
                        <v-img :src="t.img" />
                      </v-list-item-avatar>
                      <v-list-item-content class="ml-3">
                        <!-- <v-list-item-title class="black--text">{{ t.text || t.label || '-' }}</v-list-item-title> -->
                        <v-list-item-title class="black--text">{{ t[itemText] || t.label || '-' }}</v-list-item-title>
                        <v-list-item-subtitle v-if="t.subtitle">{{ t.subtitle }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-checkbox>
                  <!-- <div @click="myValue=t.value,$emit('select', t),closeSearch()" -->
                  <div
                    v-else
                    class="max-width flex-y-center"
                    style="cursor: pointer;height: 40px"
                    @click="myValue=t[itemKey],$emit('select', {t:t,val:myValue}),closeSearch()"
                  >
                    <v-list-item-avatar v-if="t.img" class="mr-4">
                      <v-img :src="t.img" />
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <slot name="select-label" :item="t">
                          <!-- {{ t.text || t.label || '-' }} -->
                          {{ t[itemText] || t.label || '-' }}
                        </slot>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="t.subtitle">{{ t.subtitle }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </div>
                </v-list-item>

              </v-list>
              <v-list-item v-else class="py-3" dense>
                <v-list-item-title class="flex-x-center">暂无下拉选项</v-list-item-title>
              </v-list-item>
            </div>
          </v-menu>
          <input
            v-if="!disabled"
            :required="required"
            :value="selectName"
            style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;pointer-events: none;"
            type="text"
          >
        </template>
        <template v-else-if="type==='textarea'">
          <textarea
            v-model="myValue"
            :disabled="disabled"
            :placeholder="(!readonly&&!disabled)&&placeholder"
            :readonly="readonly"
            :required="required"
            class="input"
            :rows="rows"
          />
          <template v-if="allowClose&&!(disabled||readonly)">
            <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="myValue=''">mdi-close</v-icon>
          </template>
        </template>

        <template v-else-if="type==='year'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" v-on="on">
                <input
                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :value="myValue&&yearList.filter(_item => _item.value === myValue).length&&yearList.filter(_item => _item.value === myValue)[0].text"
                  readonly
                  type="text"
                >
                <div class=" flex-y-center">
                  <template v-if="allowClose&&!(disabled||readonly)">
                    <v-icon v-if="myValue" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                  </template>
                  <v-icon v-if="!readonly&&!disabled" size="20">mdi-chevron-down</v-icon>
                </div>
              </div>
            </template>
            <div ref="search" class="search">
              <v-list class="pb-0" dense subheader>
                <v-list-item
                  v-for="(t,i) in yearList"
                  :key="i"
                  :ripple="false"
                  class="flex-y-center"
                  link
                >
                  <div
                    class="max-width flex-y-center"
                    style="cursor: pointer;height: 40px"
                    @click="myValue=t.value,$emit('selectDate', {val: myValue}),closeSearch()"
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        <slot name="select-label" :item="t">
                          {{ t.text || '-' }}
                        </slot>
                      </v-list-item-title>
                    </v-list-item-content>
                  </div>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
          <input
            v-if="!disabled"
            :required="required"
            :value="myValue&&yearList.filter(_item => _item.value === myValue).length&&yearList.filter(_item => _item.value === myValue)[0].text"
            style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;pointer-events: none;"
            type="text"
          >
        </template>

        <template v-else-if="type==='yearMonth'">
          <v-menu :close-on-content-click="false" nudge-bottom="5" offset-y max-width="300">
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" style="" v-on="on">
                <div class=" flex-1">
                  <input v-model="myValue" style="width:100%;" readonly :placeholder="placeholder" @input="$forceUpdate()">
                </div>
                <div class=" flex-y-center">
                  <template v-if="allowClose&&!(disabled||readonly)">
                    <v-icon v-if="myValue" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                  </template>
                  <v-icon v-if="!readonly&&!disabled" size="20">mdi-chevron-down</v-icon>
                </div>
              </div>
            </template>
            <v-card class=" flex-column elevation-0" style="width:300px;">
              <v-date-picker
                v-model="myValue"
                type="month"
                class=" flex-1"
                :no-title="true"
                locale="CN"
                color="primary"
                @change="$forceUpdate(),$emit('selectDate', {val:myValue})"
              />
            </v-card>
          </v-menu>
        </template>

        <template v-else-if="type==='date'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            left
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div :class="[{'disabled':disabled},type]" class="input flex-justify-between flex-y-center" v-on="on">
                <input

                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :required="required"
                  :value="myValue"
                  readonly
                  type="text"
                >
                <div class=" flex-y-center">
                  <template v-if="allowClose&&!(disabled||readonly)">
                    <template v-if="range">
                      <v-icon v-if="myValue&&myValue.length" size="16" @click.stop="myValue=[],$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                    <template v-else>
                      <v-icon v-if="myValue" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                  </template>
                  <v-icon v-if="!readonly&&!disabled" size="20">mdi-calendar-month-outline</v-icon>
                </div>
              </div>

            </template>
            <v-card flat>
              <calendar
                v-model="myValue"
                :range="range"
                :tips="options?options.tips:[]"
                zero
                @select="search.show=false,$emit('select', $event)"
                @input="$emit('selectDate', {val:myValue})"
              />
            </v-card>
          </v-menu>
        </template>
        <template v-else-if="type==='allTime'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" :title="myValue" v-on="on">
                <input
                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :required="required"
                  :value="myValue"
                  readonly
                  type="text"
                >
                <div class=" flex-y-center">
                  <template v-if="allowClose&&!(disabled||readonly)">
                    <template v-if="range">
                      <v-icon v-if="myValue&&myValue.length" size="16" @click.stop="myValue=[],$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                    <template v-else>
                      <v-icon v-if="myValue" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
                    </template>
                  </template>
                  <v-icon v-if="!readonly&&!disabled" size="20">mdi-chevron-down</v-icon>
                </div>
                <!--                <v-icon size="20" v-if="!readonly&&!disabled">mdi-calendar-clock</v-icon>-->
              </div>

            </template>
            <v-card flat>
              <calendar
                v-model="myValue"
                :tips="options?options.tips:[]"
                all-time
                time
                zero
                @input="search.show=false,$emit('selectDate', {val:myValue})"
                @select="$emit('select', myValue)"
              />
            </v-card>
          </v-menu>
        </template>
        <template v-else-if="type==='time'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" v-on="on">
                <input
                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :required="required"
                  :value="myValue"
                  readonly
                  type="text"
                >
                <!--                <v-icon size="20" v-if="!readonly&&!disabled">mdi-calendar-clock</v-icon>-->
              </div>

            </template>
            <v-card flat>
              <calendar
                v-model="myValue"
                :tips="options?options.tips:[]"
                time
                zero
                @input="search.show=false"
                @select="$emit('select', myValue)"
              />
            </v-card>
          </v-menu>
        </template>
        <template v-else-if="type==='allTime-pickers'">
          <div class="flex-y-center text-body-2 px-2">
            <input
              v-model="time.hour"
              class="flex-1 px-1"
              max="23"
              min="0"
              type="number"
            >
            <div class="flex-shrink-0 mx-1">时</div>
            <input
              v-model="time.minute"
              class="flex-1  px-1"
              max="59"
              min="0"
              type="number"
            >
            <div class="flex-shrink-0 ml-1">分</div>
            <input
              v-model="time.second"
              class="flex-1  px-1"
              max="59"
              min="0"
              type="number"
            >
            <div class="flex-shrink-0 ml-1">秒</div>
            <template v-if="allowClose&&!(disabled||readonly)">
              <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="cleanIt">mdi-close</v-icon>
            </template>
          </div>
        </template>
        <template v-else-if="type==='time-pickers'">
          <div class="flex-y-center text-body-2 px-2">
            <input
              v-model="time.hour"
              class="flex-1 px-1"
              max="23"
              min="0"
              type="number"
            >
            <div class="flex-shrink-0 mx-1">时</div>
            <input
              v-model="time.minute"
              class="flex-1  px-1"
              max="59"
              min="0"
              type="number"
            >
            <div class="flex-shrink-0 ml-1">分</div>
            <template v-if="allowClose&&!(disabled||readonly)">
              <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="cleanIt">mdi-close</v-icon>
            </template>
          </div>
        </template>
        <template v-else-if="type==='tree'">
          <div class="input" style="height: 300px;overflow: auto">
            <tree
              ref="tree"
              :data="options"
              :default-checked-keys="tree.defaultKeys"
              :props="tree.props"
              node-key="id"
              show-checkbox
              @check="checkChange"
            />
          </div>
        </template>
        <template v-else-if="type==='switch'">
          <v-switch
            v-model="myValue"
            :disabled="disabled||readonly"
            :false-value="options&&options['false-value']"
            :ripple="false"
            :true-value="(options&&options['true-value'])||true"
            class="mt-0 pt-1"
            color="info"
            hide-details
            inset
          />
        </template>
        <template v-else-if="type==='slider'">
          <v-slider
            v-model="myValue"
            :max="options.max"
            :min="options.min"
            hide-details
          />
        </template>
        <template v-else-if="type==='checkbox'">
          <!--          flex-justify-around-->
          <div class=" flex-row">

            <!-- <v-checkbox
              :disabled="disabled||readonly"
              :key="index"
              :ripple="false"
              :value="item.value"
              class="mt-0 pt-0 mr-3"
              hide-details
              v-for="(item,index) in options"
              v-model="myValue"
            > -->
            <v-checkbox
              v-for="(item,index) in options"
              :key="index"
              v-model="myValue"
              :disabled="disabled||readonly"
              :ripple="false"
              :value="item[itemKey]"
              class="mt-0 pt-0 mr-3"
              hide-details
            >
              <template v-slot:label>
                <!-- <div class="body-2 pl-1 font-regular grey--text text--darken-4">{{ item.text }}</div> -->
                <div class="body-2 pl-1 font-regular grey--text text--darken-4">{{ item[itemText] }}</div>
              </template>
            </v-checkbox>
          </div>

        </template>
        <template v-else-if="type==='radio'">
          <v-radio-group
            v-model="myValue"
            :disabled="disabled||readonly"
            class="mt-0 pt-0"
            hide-details
            row
            @change="$emit('select', {val:myValue})"
          >
            <!-- @change="$emit('select', {t:t,val:myValue})" -->
            <!-- <v-radio :key="index" :ripple="false" :value="item.value" -->
            <v-radio
              v-for="(item,index) in options"
              :key="index"
              :ripple="false"
              :value="item[itemKey]"
            >
              <template v-slot:label>
                <!-- <div class="body-2 pl-1 font-regular grey--text text--darken-4">{{ item.text }}</div> -->
                <div class="body-2 pl-1 font-regular grey--text text--darken-4">{{ item[itemText] }}</div>
              </template>
            </v-radio>
            <div class=" flex-y-center">
              <!-- <template v-if="allowClose&&!(disabled||readonly)">
                <v-icon v-if="myValue" size="16" @click.stop="myValue=''">mdi-close</v-icon>
              </template> -->
              <v-icon v-if="!(disabled||readonly)&&allowClose&&myValue" color="primary" size="18" title="清空" @click="myValue='',$forceUpdate(),$emit('cleanVal', {val:myValue})">mdi-replay</v-icon>
            </div>
          </v-radio-group>
        </template>
        <template v-else-if="type==='color'">
          <v-menu
            v-model="search.show"
            :close-on-content-click="false"
            :disabled="disabled||readonly"
            max-width="300"
            nudge-bottom="5"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <div class="input flex-justify-between flex-y-center" v-on="on">
                <input
                  :placeholder="(!readonly&&!disabled)&&placeholder"
                  :required="required"
                  :value="myValue"
                  readonly
                  type="text"
                >
                <v-icon size="20">mdi-calendar-month-outline</v-icon>
              </div>

            </template>
            <v-color-picker v-model="myValue" show-swatches width="300" />
          </v-menu>
        </template>
        <!-- <template v-else-if="type==='list'">
          <div>
            <ul class="pa-0 max-width">
              <li :key="index" class="flex-y-center flex-justify-between mb-4" v-for="(item,index) in myValue">
                <div class="form-item-input mr-4">
                  <input class="input" placeholder="text" type="text" v-model="item.text" />
                </div>
                <div class="form-item-input mr-4">
                  <input class="input" placeholder="value" type="text" v-model="item.value" />
                </div>
                <div class="flex-shrink-0">
                  <v-icon @click="$delete(myValue,index)" color="red">mdi-close</v-icon>
                </div>
              </li>
            </ul>
            <v-btn @click="myValue.push({text:`选项${myValue.length+1}`,value:myValue.length+1})" color="info"
                   depressed small>添加
            </v-btn>
          </div>
        </template> -->
        <template v-else-if="type==='money'">
          <input
            v-model="myValue"
            :disabled="disabled"
            :placeholder="(!readonly&&!disabled)&&placeholder"
            :readonly="readonly"
            :step="step"
            class="input"
            type="number"
            @input="changeInput"
          >
          <!-- pattern="^\d*(\.\d{0,1})?$" -->
          <!-- @input="myValue = options&&options.just?myValue:myValue.replace(/[^0-9]/g, '')" -->
          <template v-if="allowClose&&!(disabled||readonly)">
            <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="myValue=''">mdi-close</v-icon>
          </template>
        </template>
        <template v-else-if="type==='number'">
          <input
            v-model="myValue"
            :disabled="disabled"
            :placeholder="(!readonly&&!disabled)&&placeholder"
            :readonly="readonly"
            :step="step"
            class="input"
            type="number"
            @keyup.enter="$emit('onEnter', myValue)"
            @input="changeInput"
          >
          <!-- pattern="^\d*(\.\d{0,1})?$" -->
          <!-- @input="myValue = options&&options.just?myValue:myValue.replace(/[^0-9]/g, '')" -->
          <template v-if="allowClose&&!(disabled||readonly)">
            <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="myValue=''">mdi-close</v-icon>
          </template>
        </template>
        <!-- 文本 排序 -->
        <template v-else-if="type==='sort'">
          <input
            v-model="myValue"
            :disabled="disabled"
            :placeholder="(!readonly&&!disabled)&&placeholder"
            :readonly="readonly"
            :type="type"
            class="input"
            @blur="$emit('blur', myValue)"
            @keyup="$emit('keyup', myValue)"
            @keyup.enter="$emit('onEnter', myValue)"
            @input="changeInput"
          >
          <template v-if="allowClose&&!(disabled||readonly)">
            <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
          </template>
        </template>
        <!-- 文本 input -->
        <template v-else>
          <input
            v-model="myValue"
            :disabled="disabled"
            :placeholder="(!readonly&&!disabled)&&placeholder"
            :readonly="readonly"
            :type="type"
            :title="disabled?myValue:''"
            class="input"
            @blur="$emit('blur', myValue)"
            @keyup="$emit('keyup', myValue)"
            @keyup.enter="$emit('onEnter', myValue)"
            @input="changeInput"
          >
          <template v-if="allowClose&&!(disabled||readonly)">
            <v-icon v-if="myValue" class="mr-1" size="16" @click.stop="myValue='',$emit('cleanVal', {val:myValue})">mdi-close</v-icon>
          </template>
        </template>
        <input
          v-if="!disabled"
          :required="required"
          :value="myValue"
          style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;pointer-events: none;"
          type="text"
        >
        <a
          v-if="appendIcon&&!readonly&&!disabled"
          class="flex-shrink-0 flex-center px-2 grey lighten-5"
          style="height: 40px"
          @click="$emit('click:appendIcon')"
        >
          <v-icon>{{ appendIcon }}</v-icon>
        </a>
        <slot name="append" />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object, String, Boolean, Number],
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: ''
    },
    disabled: { // 禁止
      type: Boolean
    },
    isShowLabel: { // 是否显示标签
      type: Boolean,
      default: true
    },
    readonly: { // 只读状态
      type: Boolean
    },
    required: { // 必填
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: [Array, Object],
      default: () => {}
    },
    noSelect: { // 下拉隐藏搜索框 数据小于十条自动隐藏
      type: Boolean
    },
    range: {
      type: Boolean
    },
    multiple: {
      type: Boolean
    },
    direction: {
      type: String,
      default: ''
    },
    prepend: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean
    },
    line: {
      type: Boolean
    },
    step: {
      type: [Number, String],
      default: 0.01
    },
    appendIcon: {
      type: [String],
      default: ''
    },
    defaultValue: {
      type: Boolean
    },
    dense: {
      type: Boolean
    },
    rows: {
      type: Number,
      default: 5
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemKey: {
      type: String,
      default: 'value'
    },
    allowClose: {
      type: Boolean
    },
    defaultText: {
      type: String,
      default: ''
    },
    min: {
      type: [Array, Object, String, Number, Date],
      default: () => {}
    },
    max: {
      type: [Array, Object, String, Number, Date],
      default: () => {}
    },
    pattern: { // 正则吗
      type: String,
      default: ''
    }

  },
  data() {
    return {
      myValue: this.value,
      search: {
        show: false,
        input: '',
        list: [],
        all: [],
        page: 1,
        pageMax: 1,
        pageSize: 30
      },
      tree: {
        defaultKeys: [],
        props: {
          children: 'children',
          label: 'title',
          name: 'key'
        }
      },
      time: {
        hour: '',
        minute: '',
        second: ''
      },
      yearList: []
    }
  },
  computed: {
    selectName() {
      let text = ''
      if (this.multiple && this.myValue) {
        this.myValue.forEach((item) => {
          // let arr = this.options.find(_item => _item.value === item)
          const arr = this.options.find(_item => _item[this.itemKey] === item)
          // if (arr) text += arr.text + '，'
          if (arr) text += arr[this.itemText] + '，'
        })
        if (text) text = text.substring(0, text.length - 1)
      } else {
        // let arr = this.options.find(item => item.value === this.myValue)
        const arr = this.options.find(item => item[this.itemKey] === this.myValue)
        // if (arr) text = arr.text
        if (arr) text = arr[this.itemText]
      }
      return text || this.defaultText
    }
  },
  watch: {
    value(newVal) {
      if (this.type === 'time-pickers' && newVal) {
        this.time.hour = newVal.split(':')[0]
        this.time.minute = newVal.split(':')[1]
      }
      if (this.type === 'allTime-pickers' && newVal) {
        this.time.hour = newVal.split(':')[0]
        this.time.minute = newVal.split(':')[1]
        this.time.second = newVal.split(':')[2]
      }

      this.myValue = newVal
    },
    myValue(newVal) {
      if (!this.multiple && !this.type === 'color') this.search.show = false
      if (this.type === 'select' && this.defaultValue && !this.value && this.options && this.options.length) {
        // newVal = this.options[0].value
        newVal = this.options[0][this.itemKey]
      }
      // if (this.pattern && this.pattern === 'zhengshu' && newVal) { // 项目管理/文档管理 文件名 正则
      //   const val = newVal
      //   let value = val.replace(/[^-\d]/g, '') // 只能输入-和数字
      //   value = value.replace(/-{2,}/g, '-') // -只能保留一个
      //   value = value.replace(/(\d)-/g, '$1') // 数字后面不能接-，不能出现类似-11-2,12-，11-23
      //   value = value.replace(/-(0+)/g, '0') // 不能出现-0,-001,-0001类似
      //   value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      //   newVal = value.replace(/(-?\d{15})\d*/, '$1') // 最多保留15位整数
      //   this.myValue = newVal
      // }
      if (this.pattern) { // 正则
        if (this.pattern === 'zhengshu' && newVal) {
          const val = newVal
          let value = val.replace(/[^-\d]/g, '') // 只能输入-和数字
          value = value.replace(/-{2,}/g, '-') // -只能保留一个
          value = value.replace(/(\d)-/g, '$1') // 数字后面不能接-，不能出现类似-11-2,12-，11-23
          value = value.replace(/-(0+)/g, '0') // 不能出现-0,-001,-0001类似
          value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          newVal = value.replace(/(-?\d{15})\d*/, '$1') // 最多保留15位整数
          this.myValue = newVal
        } else if (this.pattern === 'sort' && newVal) { // 排序 1~9999
          const val = newVal
          let value = val.replace(/[^\d]/g, '') // 只能输入数字
          value = value.replace(/^(0+)/, '') // 不能出现0开头
          newVal = value.replace(/(\d{4})\d*/, '$1') // 最多保留4位整数
          this.myValue = newVal
        } else if (this.pattern === 'money' && newVal) { // 最多两位小数点
          let value = newVal
          value = value.replace(/[^\d.。]/g, '') // 清除“数字”和“.”以外的字符
          value = value.replace(/。/g, '.') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          value = value.replace(/^(\.)/, '') // 第一位.开头，去掉
          value = value.replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
          // value = value.replace(/./g, '') // 不能出现-0,-001,-0001类似
          value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
          let r = /^(\.)*(\d+)\.(\d\d).*$/
          if (this.step === 0.1) {
            r = /^(\.)*(\d+)\.(\d).*$/
          }
          // value = value.replace(/^(\.)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
          value = value.replace(r, '$1$2.$3') // 小数位数
          newVal = value
          this.myValue = newVal
        } else if (this.pattern === 'justInteger' && newVal) { // 正整数 包括0
          let value = newVal.replace(/^0*(0\.|[1-9])/, '$1') // 粘贴不生效
          value = value.replace(/[^\d]/g, '') // 清除“数字”以外的字符
          value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          newVal = value
          this.myValue = newVal
        } else if (this.pattern === 'minNumber' && newVal) {
          const val = newVal
          let value = val.replace(/[^\d]/g, '') // 只能输入数字
          value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          // value = value.replace(/^(0+)/, '') // 不能出现0开头
          if (value < 0) {
            value = 0
          }
          if (this.max && (value > this.max)) {
            value = Number(this.max)
          }
          newVal = value
          this.myValue = newVal
        } else if (this.pattern === 'maxNumber' && newVal) {
          const val = newVal
          let value = val.replace(/[^\d]/g, '') // 只能输入数字
          value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          // value = value.replace(/^(0+)/, '') // 不能出现0开头
          if (value < 0) {
            value = 0
          }
          if (this.min && (this.min > value)) {
            value = Number(this.min)
          }
          newVal = value
          this.myValue = newVal
        }
      }
      this.$emit('input', newVal)
    },
    options() {
      const the = this
      const value = []
      function f(arr, id) {
        arr.forEach((item, index) => {
          index++
          index = index.toString()
          const i = id ? (id + '-' + index) : index
          the.$set(item, 'id', i)
          if (item.checked) value.push(i)
          if (item.children && item.children.length) {
            f(item.children, i)
          }
        })
      }
      if (this.type === 'tree') {
        f(this.options)

        this.tree.defaultKeys = value
        this.$nextTick(() => {
          this.checkChange()
        })
      } else if (this.type === 'select') {
        if (this.multiple) this.myValue = this.value || []
        // if (this.defaultValue && !!!this.value && value && value.length) {
        if (this.defaultValue && !this.value && value && value.length) {
          // this.myValue = this.options[0].value
          this.myValue = this.options[0][this.itemKey]
        }
        this.search.all = this.options.filter(item => item.show !== false)
      }
    },
    'search.show'(newVal) {
      if (newVal) {
        const TotalRecord = this.search.all.length
        const pageSize = this.search.pageSize
        this.search.pageMax = TotalRecord % pageSize === 0 ? (TotalRecord / pageSize) : (Math.floor(TotalRecord / pageSize) + 1)
        this.search.list = this.pagination(1, this.search.pageSize, this.search.all)
      }
    },
    'search.input'(newVal) {
      function searchList(text, key, arr) {
        const list = []
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i]
          if (item[key].indexOf(text) !== -1) {
            list.push(item)
          }
        }
        return list
      }

      // this.search.all = newVal ? searchList(newVal, 'text', this.options) : this.options
      this.search.all = newVal ? searchList(newVal, this.itemText, this.options) : this.options

      const TotalRecord = this.search.all.length
      const pageSize = this.search.pageSize
      this.search.pageMax = TotalRecord % pageSize === 0 ? (TotalRecord / pageSize) : (Math.floor(TotalRecord / pageSize) + 1)
      this.search.list = this.pagination(1, this.search.pageSize, this.search.all)
      this.$refs.search.scrollTop = 0
    },
    multiple: {
      handler: function(newVal) {
        // let value = this.value;
        // if (newVal) value = value || [];
        // this.myValue = value
        this.myValue = newVal ? (this.value || []) : this.value
      },
      immediate: true
    },
    time: {
      handler: function(val) {
        // if (val.hour > 23) val.hour = 23
        // if (val.hour < 0) val.hour = 0
        // if (val.minute > 59) val.minute = 59
        // if (val.minute < 0) val.minute = 0
        // if (val.second > 59) val.second = 59
        // if (val.second < 0) val.second = 0

        // if(this.type === 'time-pickers') this.myValue = `${this.zeroPad(val.hour) || '00'}:${this.zeroPad(val.minute) || '00'}`
        // if(this.type === 'allTime-pickers') this.myValue = `${this.zeroPad(val.hour) || '00'}:${this.zeroPad(val.minute) || '00'}:${this.zeroPad(val.minute) || '00'}`
        if (this.type === 'time-pickers') {
          if (!val.hour && (val.hour !== 0) && !val.minute && (val.minute !== 0)) {
            this.myValue = ''
          } else {
            if (val.hour > 23) val.hour = 23
            if (val.hour < 0) val.hour = 0
            if (val.minute > 59) val.minute = 59
            if (val.minute < 0) val.minute = 0
            this.myValue = `${this.zeroPad(val.hour) || '00'}:${this.zeroPad(val.minute) || '00'}`
          }
        }
        if (this.type === 'allTime-pickers') {
          if (!val.hour && (val.hour !== 0) && !val.minute && (val.minute !== 0) && !val.second && (val.second !== 0)) {
            this.myValue = ''
          } else {
            if (val.hour > 23) val.hour = 23
            if (val.hour < 0) val.hour = 0
            if (val.minute > 59) val.minute = 59
            if (val.minute < 0) val.minute = 0
            if (val.second > 59) val.second = 59
            if (val.second < 0) val.second = 0
            this.myValue = `${this.zeroPad(val.hour) || '00'}:${this.zeroPad(val.minute) || '00'}:${this.zeroPad(val.minute) || '00'}`
          }
        }
      },
      deep: true
      // immediate: true
    }
  },
  created() {
  },
  mounted() {
    if (this.type === 'select') {
      this.search.all = this.options.filter(item => item.show !== false)
    }
    // if (!!!this.value && this.value !== 0) {
    if (!this.value && this.value !== 0) {
      let text = ''
      if (this.multiple) text = []
      this.myValue = text
    }
    if (this.type === 'time-pickers' && this.value) {
      this.time.hour = this.value.split(':')[0]
      this.time.minute = this.value.split(':')[1]
    }
    if (this.type === 'allTime-pickers' && this.value) {
      this.time.hour = this.value.split(':')[0]
      this.time.minute = this.value.split(':')[1]
      this.time.second = this.value.split(':')[2]
    }
    // this.myValue = this.value || [];
    // if (this.type === 'select')

    if (this.type === 'year') {
      const date = new Date().getFullYear()
      const arr = []
      for (let i = 1; i <= 30; i++) {
        arr.push({ text: i + (date - 15) + '年', value: (i + (date - 15)).toString() })
      }
      this.yearList = arr
    }
  },
  methods: {
    pagination(pageNo, pageSize, array) {
      const offset = (pageNo - 1) * pageSize
      return (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize)
    },
    // scroll(e) {
    scroll() {
      const scrollHeight = this.$refs.search.scrollHeight
      const clientHeight = this.$refs.search.clientHeight
      const scrollTop = this.$refs.search.scrollTop
      if ((scrollHeight - scrollTop === clientHeight) && (this.search.page < this.search.pageMax)) {
        this.search.page++
        this.search.list = this.search.list.concat(this.pagination(this.search.page, this.search.pageSize, this.search.all))
      }
    },
    checkChange() {
      const treeValue = this.$refs.tree.getCheckedNodes()
      const value = []
      treeValue.forEach((item) => {
        value.push(item.key)
      })
      this.myValue = value
    },
    closeSearch() {
      if (!this.multiple || !this.noSelect) {
        this.search.show = false
      }
    },
    zeroPad(n) {
      n = parseInt(n)
      return (n < 10) && n.toString() !== '00' ? '0' + n : n
    },
    changeInput() {
      if (this.type === 'money') { // 如果是钱的话 最多两位小数
        this.myValue.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      }
      this.$emit('changeInput', this.myValue)
    },
    cleanIt() {
      this.myValue = ''
      if (this.type === 'time-pickers') {
        this.time = {
          hour: '',
          minute: ''
        }
      }
      if (this.type === 'allTime-pickers') {
        this.time = {
          hour: '',
          minute: '',
          second: ''
        }
      }
    }
    // reset() {
    //   this.$emit('keyup', this.myValue)
    // }
  }
}
</script>
<style lang="scss" scoped>
.form-item {
  width: 100%;
  display: flex;
//   align-items: flex-start;
  align-items: center !important;
  /*display: flex;*/
  /*align-items: center;*/
  /*align-items: flex-start;*/

  .form-item-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: initial;
    color: #212121;
  }

  &.left {
    display: flex;
    align-items: flex-start;

    .form-item-label {
    //   line-height: 40px;
      line-height: 30px;
      margin-bottom: 0;
      margin-right: 15px;
      justify-content: flex-end;
    }

    &.dense .form-item-label {
      line-height: 30px !important;
    }

    &.min-width {
      .form-item-label {
        min-width: 100px !important;
      }
    }
  }

  .form-item-input {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    // min-height: 40px;
    min-height: 30px;
    border: 1px solid #dcdcdc;

    &.disabled {
      // border-style: dashed;

      .input {
        // background: #FFFFFF;
        background: #f3f3f3;
        color:#777 !important;
      }

    }

    &.checkbox,
    &.switch,
    &.slider,
    &.radio,
    &.list {
      min-height: auto !important;
      overflow: visible !important;
      border: 0 !important;
    }

    &.switch {
      padding: 5px 8px;
    }

    &.checkbox,
    &.radio,
    &.slider {
      padding: 8px 0 !important;
    }

    .prepend {
      height: 40px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      flex-shrink: 0;
      padding: 0 15px;
      font-size: 14px;
      color: #666666;
      border-right: 1px solid #dcdcdc;
    }
  }

  &.line {
    .form-item-input {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #757575;

      .input {
        background: #ffffff;
      }
    }
  }

  &.dense .form-item-input {
    min-height: 30px;

    &.checkbox,
    &.radio,
    &.slider {
      padding: 5px 0 !important;
    }

    .prepend {
      height: 30px;
    }
  }
}

.input {
  width: 100%;
  padding: 4px 8px;
  /*border-radius: 4px;*/
  font-size: 14px;
//   min-height: 40px;
  min-height: 30px;
  /*resize: none;*/
//   background: #FAFAFA;
  background: #Fdfdfd;
  font-weight: initial !important;

  input {
    width: 100%;
  }
}

.dense .input {
  padding: 5px;
  // min-height: 30px;
  min-height: 30px;
}

.search {
  background: #FFFFFF;
  max-height: 300px;
  overflow: auto
}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
  color:#ccc;
}
input:-ms-input-placeholder,textarea:-ms-input-placeholder{
  color:#ccc;
}
input:-moz-placeholder,textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:#ccc;
}
input::-moz-placeholder,textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:#ccc;
}

input[disabled]{
  color:#777 !important;
  background-color:#f3f3f3 !important;
}
.input.disabled{
  color:#777 !important;
  background-color:#f3f3f3 !important;
}
.disabled input[readonly]{
  color:#777 !important;
  background-color:#f3f3f3 !important;
}
.select-disabled{
  color:#777 !important;
}
.input-number-disabled{
  color:#777 !important;
}
input.menuInput{
  background-color:transparent !important;
}
</style>

