<template>
  <div v-if="show" class="dialogwrapper">
    <div class="overlay" />
    <v-card class="dialog">
      <v-card-title>{{ title || '提示' }}</v-card-title>
      <v-card-text style="font-size:14px;margin-top:6px;">
        <!-- {{ text }} -->
        <div v-html="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!hiddenCancel" text style="font-size:14px;" @click="cancel">取消</v-btn>
        <v-btn :color="error?'#F44336':'#2196f3'" style="font-size:14px;" text :loading="loading" @click="ok">{{ okText }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      promiseStatus: null,
      hiddenCancel: false,
      show: false,
      text: '',
      error: false,
      loading: false,
      title: '',
      okText: '确定'
    }
  },
  methods: {
    confirm(e) {
      const _this = this
      this.hiddenCancel = e.hiddenCancel || false
      this.okText = e.okText || '确定'
      this.show = true
      this.text = e.text
      this.title = e.title || ''
      this.error = e.error || false

      return new Promise(function(resolve, reject) {
        _this.promiseStatus = { resolve, reject }
      })
    },
    ok() {
      // this.show = false;
      this.promiseStatus && this.promiseStatus.resolve(this)
    },
    cancel() {
      this.show = false
      this.promiseStatus && this.promiseStatus.reject()
    }
  }
}
</script>
<style scoped lang="scss">
    .dialogwrapper{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0px;
        pointer-events: none;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 600000;
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) 0s, z-index 1ms ease 0s;
        outline: none;
	}
	.dialog{
		overflow-y: auto;
		pointer-events: auto;
		width: 100%;
		z-index: inherit;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
		border-radius: 4px;
		margin: 24px;
		transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
		max-width:290px;
	}
	.overlay{
		align-items: center;
		border-radius: inherit;
		display: flex;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: auto;
		background:#000;
		opacity:0.46;
	}
</style>
