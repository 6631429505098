const edition = 'ctms'
const $api = {
  randomImage: edition + '/sys/randomImage',
  login: edition + '/sys/login',
  logout: edition + '/sys/logout', // 登出
  changePassword: edition + '/sys/changePassword', // 用户-首次登录，修改密码
  // login: edition+'/site/login',
  hehe: edition + '/site/hehe',
  sendCaptchaToMail: edition + '/sys/sendCaptchaToMail', // 用户-发送验证码到邮箱/ctms/sys/sendCaptchaToMail
  checkPasswordCaptcha: edition + '/sys/checkPasswordCaptcha', // 用户-找回密码-校验验证码/ctms/sys/checkPasswordCaptcha
  resetPassword: edition + '/sys/resetPassword', // 用户-找回密码-重设密码/ctms/sys/resetPassword
  // 项目
  // projectList: edition+'/frontend/project/list', //项目列表
  // 项目 试验类型
  projectClassList: edition + '/backend/projectClass/list', // 项目 试验类型列表
  projectClassAdd: edition + '/backend/projectClass/add', // 项目 试验类型添加
  projectClassEdit: edition + '/backend/projectClass/edit', // 项目 试验类型编辑
  projectClassDelete: edition + '/backend/projectClass/delete', // 项目试验类型 删除

  // 项目 访视基线
  projectVisitBaselineList: edition + '/backend/projectVisitBaseline/list', // 项目 访视基线列表
  projectVisitBaselineAdd: edition + '/backend/projectVisitBaseline/add', // 项目 访视基线 添加
  projectVisitBaselineEdit: edition + '/backend/projectVisitBaseline/edit', // 项目 访视基线 编辑
  projectVisitBaselineDelete: edition + '/backend/projectVisitBaseline/delete', // 项目 访视基线 删除

  // 进度配置 项目进度 阶段
  progressStageList: edition + '/backend/progressStage/list', // 项目 试验类型列表
  progressStageAdd: edition + '/backend/progressStage/add', // 项目 项目进度添加
  progressStageEdit: edition + '/backend/progressStage/edit', // 项目 项目进度编辑
  progressStageDelete: edition + '/backend/progressStage/delete', // 项目 项目进度删除
  getStageAndEvent: edition + '/backend/progressStage/getStageAndEvent', // 进度阶段-获取所有阶段与事件
  progressStageSetStageStatus: edition + '/backend/progressStage/setStageStatus', // 进度阶段-进度阶段-禁用-恢复
  progressStageSetProgressCreateFlag: edition + '/backend/progressStage/setProgressCreateFlag', // 进度阶段-设置进度是否允许创建阶段
  progressStageQueryProjectList: edition + '/backend/progressStage/queryProjectList', // 进度阶段-获取前台项目列表
  progressStageGetProgressCategory: edition + '/backend/progressStage/getProgressCategory', // 进度阶段-获取进度信息
  progressStageSyncProjects: edition + '/backend/progressStage/syncProjects', // 自定义属性-同步项目
  // 进度配置 事件阶段 事件
  progressEventList: edition + '/backend/progressEvent/list', // 事件阶段列表
  progressEventAdd: edition + '/backend/progressEvent/add', // 事件阶段列表 添加
  progressEventEdit: edition + '/backend/progressEvent/edit', // 事件阶段列表 编辑
  progressEventDelete: edition + '/backend/progressEvent/delete', // 事件阶段列表 删除
  progressEventSetStageStatus: edition + '/backend/progressEvent/setStageStatus', // 事件阶段列表 禁用-恢复
  // 事件 任务列表
  progressEventTaskList: edition + '/backend/progressEvent/taskList', // 事件 任务列表
  progressEventTaskAdd: edition + '/backend/progressEvent/addTask', // 事件 任务列表 添加
  progressEventTaskEdit: edition + '/backend/progressEvent/editTask', // 事件 任务列表 编辑
  progressEventTaskDelete: edition + '/backend/progressEvent/deleteTask', // 事件 任务列表 删除
  // 事件 提醒列表
  progressEventTaskEventRemindList: edition + '/backend/progressEvent/eventRemindList', // 事件 提醒列表
  progressEventTaskEventRemindAdd: edition + '/backend/progressEvent/addEventRemind', // 事件 提醒列表 添加
  progressEventTaskEventRemindEdit: edition + '/backend/progressEvent/editEventRemind', // 事件 提醒列表 编辑
  progressEventTaskEventRemindDelete: edition + '/backend/progressEvent/deleteEventRemind', // 事件 提醒列表 删除
  // 事件 前置条件
  eventPreconditionList: edition + '/backend/progressEvent/eventPreconditionList', // 事件 前置条件列表
  addEventPrecondition: edition + '/backend/progressEvent/addEventPrecondition', // 事件 前置条件列表 添加
  editEventPrecondition: edition + '/backend/progressEvent/editEventPrecondition', // 事件 前置条件列表 编辑
  deleteEventPrecondition: edition + '/backend/progressEvent/deleteEventPrecondition', // 事件 前置条件列表 删除
  // 事件 标签节点
  eventTagList: edition + '/backend/progressEvent/eventTagList', // 事件 标签节点
  saveEventTags: edition + '/backend/progressEvent/saveEventTags', // 事件 标签节点 保存
  // 事件 节点标签
  progressEventTagList: edition + '/backend/progressEventTag/list', // 进度事件标签-分页列表查询 节点标签
  progressEventTagAdd: edition + '/backend/progressEventTag/add', // 进度事件标签-分页列表查询 节点标签 添加
  progressEventTagEdit: edition + '/backend/progressEventTag/edit', // 进度事件标签-分页列表查询 节点标签 编辑
  progressEventTagDelete: edition + '/backend/progressEventTag/delete', // 进度事件标签-分页列表查询 节点标签 删除
  // 事件 必传附件
  eventUploadList: edition + '/backend/progressEvent/eventUploadList', // 进度阶段事件-事件必传附件列表
  addEventUpload: edition + '/backend/progressEvent/addEventUpload', // 进度阶段事件-添加必传附件
  editEventUpload: edition + '/backend/progressEvent/editEventUpload', // 进度阶段事件-编辑必传附件
  deleteEventUpload: edition + '/backend/progressEvent/deleteEventUpload', // 进度阶段事件-通过id删除事件必传附件
  // 事件 文档提醒
  addEventFileRemind: edition + '/backend/progressEvent/addEventFileRemind', // 进度阶段事件-事件文档提醒
  eventFileRemindList: edition + '/backend/progressEvent/eventFileRemindList', // 进度阶段事件-事件文档详情
  deleteEventFileRemind: edition + '/backend/progressEvent/deleteEventFileRemind', // 进度阶段事件-事件文档删除
  editEventFileRemind: edition + '/backend/progressEvent/editEventFileRemind', // 进度阶段事件-事件文档编辑

  // 文件夹配置
  folderTemplateGetFolderTree: edition + '/backend/folderTemplate/getFolderTree', // 文件夹-获取目录树(弃用旧接口)
  folderTemplateGetFolderTreeByCatalogueId: edition + '/backend/folderTemplate/getFolderTreeByCatalogueId', // 文件夹-获取目录树
  folderTemplateAdd: edition + '/backend/folderTemplate/add', // 文件夹-添加
  folderTemplateEdit: edition + '/backend/folderTemplate/edit', // 文件夹-编辑
  folderTemplateDelete: edition + '/backend/folderTemplate/delete', // 文件夹-删除
  folderTemplateGetFolderPermission: edition + '/backend/folderTemplate/getFolderPermission', // 文件夹-获取文件夹权限
  folderTemplateSaveFolderPermission: edition + '/backend/folderTemplate/saveFolderPermission', // 文件夹-保存文件夹权限
  folderTemplateQueryProjectList: edition + '/backend/folderTemplate/queryProjectList', // 文件夹-获取前台项目列表
  folderTemplateSyncProjects: edition + '/backend/folderTemplate/syncProjects', // 自定义属性-同步项目
  folderTemplateMoveFolder: edition + '/backend/folderTemplate/moveFolder', // 文件夹-移动文件夹
  folderTemplateSetFolderStatus: edition + '/backend/folderTemplate/setFolderStatus', // 文件夹-设置文件夹状态 禁用恢复
  folderTemplateFileTemplateList: edition + '/backend/folderTemplate/fileTemplateList', // 文件夹-文档模板列表
  folderTemplateFileRename: edition + '/backend/folderTemplate/fileRename', // 文件夹-文档模板重命名
  folderTemplateFileTemplateUpload: edition + '/backend/folderTemplate/fileTemplateUpload', // 文件夹-文档模板上传
  folderTemplateFileDelete: edition + '/backend/folderTemplate/fileDelete', // 文件夹-文档模板删除
  folderTemplateFileDownload: edition + '/backend/folderTemplate/fileDownload', // 文件夹-文档模板下载
  // lanyijian目录配置
  projectCatalogueGetProjectCatalogue: edition + '/backend/projectCatalogue/getProjectCatalogue', // 文件夹配置-目录列表
  projectCatalogueAddProjectCatalogue: edition + '/backend/projectCatalogue/addProjectCatalogue', // 文件夹配置-目录添加
  projectCatalogueDelete: edition + '/backend/projectCatalogue/delete', // 文件夹配置-目录删除
  projectCatalogueSetProjectCatalogueStatus: edition + '/backend/projectCatalogue/setProjectCatalogueStatus', // 文件夹配置-目录恢复、禁用
  projectCatalogueUpdateProjectCatalogue: edition + '/backend/projectCatalogue/updateProjectCatalogue', // 文件夹配置-目录编辑
  projectCatalogueGetProjectCatalogueList: edition + '/backend/projectCatalogue/getProjectCatalogueList', // 目录-目录列表(不分页)

  // 项目自动编号
  autoNumberList: edition + '/backend/autoNumber/list', // 项目自动编号列表
  autoNumberSave: edition + '/backend/autoNumber/save', // 编辑自动编号
  // autoNumberCheckAutoNum: edition + '/backend/autoNumber/checkAutoNum', // 检查自动编号

  // 工时任务
  taskTemplateTree: edition + '/backend/taskTemplate/tree', // 任务模板-获取任务模板树
  taskTemplateAdd: edition + '/backend/taskTemplate/add', // 任务模板-添加任务模板
  taskTemplateEdit: edition + '/backend/taskTemplate/edit', // 任务模板-编辑任务模板
  taskTemplateDelete: edition + '/backend/taskTemplate/delete', // 任务模板-删除任务模板
  taskTemplateDisable: edition + '/backend/taskTemplate/disable', // 任务模板-禁用任务模板
  taskTemplateEnable: edition + '/backend/taskTemplate/enable', // 任务模板-启用任务模板
  taskTemplateManhourGet: edition + '/backend/taskTemplate/manhourGet', // 工时管理 获取标准工时
  taskTemplateManhourEdit: edition + '/backend/taskTemplate/manhourEdit', // 工时管理 编辑标准工时

  // 合作单位
  partnerList: edition + '/backend/partner/partnerList', // 合作单位-组织单位列表
  partnerAddressBook: edition + '/backend/partner/partnerAddressBook', // 合作单位-组织单位通讯录

  // 菜单权限
  getSystemMenuList: edition + '/sys/permission/getSystemMenuList', // 菜单权限-分页列表查询
  getSystemSubmenu: edition + '/sys/permission/getSystemSubmenu', // 菜单权限-查询子菜单
  getUserPermissionByToken: edition + '/sys/permission/getUserPermissionByToken', // 菜单权限
  permissionAdd: edition + '/sys/permission/add', // 菜单权限 添加
  permissionEdit: edition + '/sys/permission/edit', // 菜单权限 编辑
  permissionDelete: edition + '/sys/permission/delete', // 菜单权限 删除
  permissionQueryTreeList: edition + '/sys/permission/queryTreeList', // 菜单权限-获取全部的权限树
  permissionQueryRolePermission: edition + '/sys/permission/queryRolePermission', // 菜单权限-查询角色授权
  permissionSaveRolePermission: edition + '/sys/permission/saveRolePermission', // 菜单权限-保存角色授权

  permissionQueryReportTreeList: edition + '/sys/permission/queryReportTreeList', // 菜单权限-获取报告权限树
  permissionQueryRoleReportPermission: edition + '/sys/permission/queryRoleReportPermission', // 菜单权限-查询角色报告授权
  permissionSaveRoleReportPermission: edition + '/sys/permission/saveRoleReportPermission', // 菜单权限-保存角色报告授权

  // 部门管理
  queryDepartTreeSync: edition + '/sys/sysDepart/queryDepartTreeSync', // 部门管理
  sysDepartAdd: edition + '/sys/sysDepart/add', // 部门管理 添加
  sysDepartEdit: edition + '/sys/sysDepart/edit', // 部门管理 编辑
  sysDepartDelete: edition + '/sys/sysDepart/delete', // 部门管理 删除
  sysDepartDeleteBatch: edition + '/sys/sysDepart/deleteBatch', // 部门管理 批量删除
  sysDepartQueryTreeList: edition + '/sys/sysDepart/queryTreeList', // 部门-查出所有部门,并以树结构数据格式响应给前端
  getUsersByDepartId: edition + '/sys/sysDepart/getUsersByDepartId', // 部门-根据部门id获取用户信息
  setDepartManager: edition + '/sys/sysDepart/setDepartManager', // 部门-设置部门主管

  // 用户
  userList: edition + '/sys/user/list', // 用户列表
  userAdd: edition + '/sys/user/add', // 用户 添加
  userEdit: edition + '/sys/user/edit', // 用户 编辑
  userDelete: edition + '/sys/user/delete', // 用户 删除
  userRecovery: edition + '/sys/user/recovery', // 用户 恢复
  userFrozenBatch: edition + '/sys/user/frozenBatch', // 用户 解锁 锁定
  userQueryById: edition + '/sys/user/queryById', // 用户-根据ID查询
  userQueryUserRole: edition + '/sys/user/queryUserRole', // 用户-查询用户角色
  userUserDepartList: edition + '/sys/user/userDepartList', // 用户-查询用户部门
  departUserList: edition + '/sys/user/departUserList', // 用户-部门用户列表
  editSysDepartWithUser: edition + '/sys/user/editSysDepartWithUser', // 用户-给指定部门添加对应的用户
  deleteUserInDepart: edition + '/sys/user/deleteUserInDepart', // 用户-将用户从部门移除
  userChangePassword: edition + '/sys/user/changePassword', // 用户-修改用户密码
  userGetRelatedProjects: edition + '/sys/user/getRelatedProjects', // 用户-获取用户关联项目
  resetUserPassword: edition + '/sys/user/resetUserPassword', // 用户-重置密码
  userExportList: edition + '/sys/user/exportList', // 用户-导出
  userExportUserTemplate: edition + '/sys/user/exportUserTemplate', // 用户-导出模板
  userImportUserTemplate: edition + '/sys/user/importUserTemplate', // 用户-导入

  // 组织角色
  roleList: edition + '/sys/role/list', // 角色列表
  roleListAll: edition + '/sys/role/queryAll', // 角色列表
  exportRoleList: edition + '/sys/role/exportRoleList', // 角色列表导出
  roleAdd: edition + '/sys/role/add', // 角色 添加
  roleEdit: edition + '/sys/role/edit', // 角色 编辑
  roleDelete: edition + '/sys/role/delete', // 角色 删除
  roleQueryAll: edition + '/sys/role/queryAll', // 角色-查询所有
  setRoleStatus: edition + '/sys/role/setRoleStatus', // 角色-禁用-恢复
  getRoleUserList: edition + '/sys/role/roleUserList', // 角色-角色用户列表

  // 属性配置
  customFieldList: edition + '/backend/customField/list', // 属性配置列表
  customFieldSave: edition + '/backend/customField/save', // 属性配置添加
  customFieldDelete: edition + '/backend/customField/delete', // 属性配置删除
  customFieldDeleteBatch: edition + '/backend/customField/deleteBatch', // 属性配置批量删除
  customFieldQueryById: edition + '/backend/customField/queryById', // 属性配置 通过id查询
  customFieldQueryProjectList: edition + '/backend/customField/queryProjectList', // 属性配置 获取前台项目列表
  customFieldSyncProjects: edition + '/backend/customField/syncProjects', // 属性配置 同步项目
  customFieldSetFieldStatus: edition + '/backend/customField/setFieldStatus', // 属性配置 禁用 恢复
  customFieldCategoryList: edition + '/backend/customField/categoryList', // 自定义字段-分类列表
  customFieldFieldTypeList: edition + '/backend/customField/fieldTypeList', // 自定义字段-自定义类型列表
  customFieldSortFields: edition + '/backend/customField/sortFields', // 自定义字段-排序

  // 项目报告模板
  projectReportList: edition + '/backend/projectReport/list', // 项目报告模板-分页列表查询
  projectReportAdd: edition + '/backend/projectReport/add', // 项目报告模板-添加
  projectReportEdit: edition + '/backend/projectReport/edit', // 项目报告模板-编辑
  projectReportDelete: edition + '/backend/projectReport/delete', // 项目报告模板-删除
  projectReportSetReportStatus: edition + '/backend/projectReport/setReportStatus', // 项目报告模板-禁用-恢复
  projectReportVersionList: edition + '/backend/projectReport/versionList', // 报告模板列表 版本
  projectReportAddVersion: edition + '/backend/projectReport/addVersion', // 添加报告模板 版本
  projectReportEditVersion: edition + '/backend/projectReport/editVersion', // 编辑报告模板 版本
  projectReportDeleteVersion: edition + '/backend/projectReport/deleteVersion', // 删除报告模板 版本
  projectReportSetVersionStatus: edition + '/backend/projectReport/setVersionStatus', // 禁用-恢复版本
  backendProjectReportUploadVersionTemplate: edition + '/backend/projectReport/uploadVersionTemplate', // 模版上传
  backendProjectReportVersionTemplateDownload: edition + '/backend/projectReport/versionTemplateDownload', // 模版下载
  backendFormOutByReportId: edition + '/backend/form/outByReportId', // 导出标签

  // 表单配置
  formDirList: edition + '/backend/formdir/list', // 表单目录列表
  formDirAdd: edition + '/backend/formdir/add', // 表单目录添加
  formDirEdit: edition + '/backend/formdir/edit', // 表单目录编辑
  formDirDelete: edition + '/backend/formdir/delete', // 表单目录删除
  formDirDeleteBatch: edition + '/backend/formdir/deleteBatch', // 表单目录批量删除
  formDirQueryById: edition + '/backend/formdir/queryById', // 表单目录 表单目录-通过id查询

  // 表单模板-项目套件
  formProjectField: edition + '/backend/form/projectField', // 项目套件
  formSubjectField: edition + '/backend/form/subjectField', // 受试者套件
  formVisitField: edition + '/backend/form/visitField', // 表单模板-SDV访视套件
  formPartnerField: edition + '/backend/form/partnerField', // 表单模板-中心套件
  formProtocolField: edition + '/backend/form/protocolField', // 表单模板-方案违背套件
  formProblemField: edition + '/backend/form/problemField', // 表单模板-发现问题
  formAuditField: edition + '/backend/form/auditField', // 表单模板-稽查发现
  formSaeField: edition + '/backend/form/saeField', // 表单模板-sae不良事件套件
  formEdit: edition + '/backend/form/edit', // 表单模板-编辑
  formQueryByReportId: edition + '/backend/form/queryByReportId', // 表单模板-通过报告id查询

  formSave: edition + '/backend/form/save', // 表单模板-保存

  commonUpload: edition + '/sys/common/upload', // 上传文件
  uploadAvatar: edition + '/common/uploadAvatar', // 上传头像
  commonVersion: edition + '/common/version', // 通用-获取版本号

  // 系统限制
  // 项目数限制
  projectLimitLimitList: edition + '/backend/projectLimit/limitList', // 用户项目限制列表
  projectLimitAdd: edition + '/backend/projectLimit/add', // 添加用户项目限制
  projectLimitEdit: edition + '/backend/projectLimit/edit', // 编辑用户项目限制
  projectLimitDelete: edition + '/backend/projectLimit/delete', // 删除用户项目限制
  projectLimitProjectList: edition + '/backend/projectLimit/projectList', // 获取配置项已创建项目列表
  projectLimitSetStatus: edition + '/backend/projectLimit/setStatus', // 设置配置项状态
  projectLimitUserOption: edition + '/backend/projectLimit/userOption', // 获取内部用户下拉
  exportProjectLimit: edition + '/backend/projectLimit/exportProjectLimit', // 用户项目限制列表 导出
  projectLimitGetLimitStatus: edition + '/backend/projectLimit/getLimitStatus', // 获取'用户项目限制'状态
  projectLimitSaveLimitStatus: edition + '/backend/projectLimit/saveLimitStatus', // 保存'用户项目限制'状态
  // 文件上传限制
  projectLimitGetUploadLimit: edition + '/backend/projectLimit/getUploadLimit', // 文件上传限制
  projectLimitSaveCommonInfo: edition + '/backend/projectLimit/saveCommonInfo', // 保存文件上传限制
  // 项目进度限制
  settingGetDispatchLimit: edition + '/backend/setting/getDispatchLimit', // 获取项目进度限制
  settingSaveDispatchLimit: edition + '/backend/setting/saveDispatchLimit', // 保存项目进度限制
  // 方案解读
  settingGetInterpret: edition + '/backend/setting/getInterpret', // 获取方案配置
  settingSaveInterpret: edition + '/backend/setting/saveInterpret', // 保存方案配置
  // 工时配置
  settingGetManhour: edition + '/backend/setting/getManhour', // 获取工时配置
  settingSaveManhour: edition + '/backend/setting/saveManhour', // 保存工时配置
  // 工时配置-阶段
  taskStageListPage: edition + '/backend/taskStage/listPage', // 列表get
  taskStageAdd: edition + '/backend/taskStage/add', // 新增post
  taskStageDelete: edition + '/backend/taskStage/delete', // 删除delete
  taskStageEdit: edition + '/backend/taskStage/edit', // 编辑post
  taskStageUpdateStatus: edition + '/backend/taskStage/updateStatus', // 启用禁用put
  // SAE配置
  settingGetSAE: edition + '/backend/setting/getSAE', // 获取SAE配置
  settingSaveSAE: edition + '/backend/setting/saveSAE', // 保存SAE配置

  settingSaveCommonInfo: edition + '/backend/setting/saveCommonInfo', // 公共信息保存
  settingGetCommonInfo: edition + '/backend/setting/getCommonInfo', // 公共信息获取
  settingSmtpGet: edition + '/backend/setting/smtpGet', // 系统配置-获取邮箱配置
  settingSmtpSet: edition + '/backend/setting/smtpSet', // 系统配置-保存邮箱配置
  settingGetAssociation: edition + '/backend/setting/getAssociation', // 获取是否关联数据
  settingSaveAssociation: edition + '/backend/setting/saveAssociation', // 保存关联数据
  settingUploadImage: edition + '/backend/setting/uploadImage', // 图片上传
  settingGetRecycleFileLimit: edition + '/backend/setting/getRecycleFileLimit', // 获取回收站文件保留天数
  settingSaveRecycleFileLimit: edition + '/backend/setting/saveRecycleFileLimit', // 设置回收站文件保留天数

  // 登录日志
  settingGetLoginLogList: edition + '/backend/setting/getLoginLogList', // 列表
  settingExportLoginLogList: edition + '/backend/setting/exportLoginLogList', // 导出

  // 项目奖金 奖金配置
  getBonusStageList: edition + '/backend/Bonus/stageList', // 默认阶段事件设置-阶段列表
  bonusAddStage: edition + '/backend/Bonus/addStage', // 默认阶段事件设置-添加阶段
  bonusEditStage: edition + '/backend/Bonus/editStage', // 默认阶段事件设置-编辑阶段
  bonusDeleteStage: edition + '/backend/Bonus/deleteStage', // 默认阶段事件设置-删除阶段
  getBonusEventList: edition + '/backend/Bonus/eventList', // 默认阶段事件设置-事件列表
  bonusAddEvent: edition + '/backend/Bonus/addEvent', // 默认阶段事件设置-添加事件
  bonusEditEvent: edition + '/backend/Bonus/editEvent', // 默认阶段事件设置-编辑事件
  bonusDeleteEvent: edition + '/backend/Bonus/deleteEvent', // 默认阶段事件设置-删除事件
  getBonusProjectBonusTemplateList: edition + '/backend/Bonus/projectBonusTemplateList', // 默认阶段事件设置-同步列表
  bonusProjectBonusTemplateSync: edition + '/backend/Bonus/projectBonusTemplateSync', // 默认阶段事件设置-同步
  // 项目奖金
  getBonusExamineUsers: edition + '/backend/setting/getBonusExamineUsers', // 获取奖金审阅用户
  saveBonusExamineUsers: edition + '/backend/setting/saveBonusExamineUsers', // 设置奖金审阅用户
  projectBonusExamineList: edition + '/backend/setting/projectBonusExamineList', // 审阅用户项目同步列表
  projectBonusExamineSync: edition + '/backend/setting/projectBonusExamineSync', // 审阅用户项目批量同步

  // 稽查功能配置
  settingGetAuditFunction: edition + '/backend/setting/getAuditFunction', // 获取稽查功能配置
  settingSaveAuditFunction: edition + '/backend/setting/saveAuditFunction', // 稽查功能配置

  // 定时任务
  sysQuartzJobList: edition + '/sys/quartzJob/list', // 列表
  sysQuartzJobPause: edition + '/sys/quartzJob/pause', // 暂停
  sysQuartzJobResume: edition + '/sys/quartzJob/resume', // 恢复定时任务
  sysQuartzJobExecute: edition + '/sys/quartzJob/execute', // 立即执行
  // 列表：/sys/quartzJob/list?pageNo=1&pageSize=10
  // 暂停：/sys/quartzJob/pause?id=xxxx
  // 恢复定时任务: /sys/quartzJob/resume?id=xxx
  // 立即执行：/sys/quartzJob/execute?id=xxx

  // 账号配置
  getAccountInfot: edition + '/backend/setting/getAccountInfo', // 获取账号配置
  saveAccountInfo: edition + '/backend/setting/saveAccountInfo', // 保存账号配置

  sysVersionQueryPageList: edition + '/backend/sysVersion/queryPageList', // 系统版本表-列表
  sysVersionAdd: edition + '/backend/sysVersion/add', // 系统版本表-添加
  sysVersionEdit: edition + '/backend/sysVersion/edit', // 系统版本表-编辑
  sysVersionDelete: edition + '/backend/sysVersion/delete', // 系统版本表-删除
  sysVersionEnable: edition + '/backend/sysVersion/enable', // 系统版本表-启用
  sysVersionDisabled: edition + '/backend/sysVersion/disabled', // 系统版本表-禁用
  sysVersionQueryById: edition + '/backend/sysVersion/queryById', // 系统版本表-查询详情
  sysVersionQueryNewSysVersion: edition + '/backend/sysVersion/queryNewSysVersion' // 系统版本表-获取最新版本

}
// export default {
//     install(vue){
//         vue.prototype.$api=api
//     }
// }
export default $api
