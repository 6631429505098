<template>
  <v-dialog v-model="dialog.show" width="550">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-btn
          v-bind="attrs"
          :loading="btnLoading"
          small
          depressed
          style=""
          class=" mr-2 pa-0 primary--text"
          title="导入"
          width="32px"
          min-width="34"
          height="30"
          v-on="on"
          @click="open"
        >
          <!-- <v-icon size="18" class=" cursor_pointer">
            mdi-file-import-outline
            mdi-import
          </v-icon> -->
          <svg t="1663064806539" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4977" width="12" height="12"><path d="M926.72 512v350.72a64 64 0 0 1-64 64h-704a64 64 0 0 1-64-64v-704a64 64 0 0 1 64-64H448V0H128a128 128 0 0 0-128 128v768a128 128 0 0 0 128 128h768a128 128 0 0 0 128-128V512z" p-id="4978" fill="#338bff" /><path d="M399.36 48.64a48 48 0 0 0 24.32 42.24 47.36 47.36 0 0 0 48.64 0 48 48 0 0 0 24.32-42.24 48.64 48.64 0 1 0-97.28 0zM926.72 512a48.64 48.64 0 1 0 48.64-48.64 48.64 48.64 0 0 0-48.64 48.64z m-109.44-79.36a49.28 49.28 0 0 0-68.48 0L613.12 568.32a571.52 571.52 0 0 1-4.48-64 474.24 474.24 0 0 1 236.8-410.24L796.8 7.68A568.96 568.96 0 0 0 512 501.76v39.68l-108.8-108.8a48.64 48.64 0 0 0-69.12 0 49.92 49.92 0 0 0 0 69.12l207.36 207.36a49.92 49.92 0 0 0 69.12 0l206.72-207.36a49.28 49.28 0 0 0 0-69.12z" p-id="4979" fill="#338bff" /><path d="M772.48 48.64a48 48 0 0 0 24.32 42.24 47.36 47.36 0 0 0 48.64 0 48 48 0 0 0 24.32-42.24 48.64 48.64 0 0 0-24.32-42.24 50.56 50.56 0 0 0-48.64 0 48.64 48.64 0 0 0-24.32 42.24z" p-id="4980" fill="#338bff" /></svg>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="flex-0 flex-justify-between flex-y-center">
        <span class="text-h6">导入</span>
        <v-icon @click="dialog.show=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-sheet class="pa-4 pt-10 overflow-auto">
        <div class="mb-5 flex-align-start flex-justify-between">
          <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
            <span class="red--text ml-1">*</span>导入：
          </div>
          <div class=" flex-1 flex-column">
            <div class=" flex-y-center">
              <!-- v-model="dialog.file[0].name" -->
              <form-item
                :value="dialog.file.name"
                class=" flex-1 mr-0"
                placeholder="文件名称"
                disabled
                type="text"
              />

              <v-btn :loading="btnLoading" small depressed style="" color="primary" class=" ml-2 pa-0" title="导入" width="80" min-width="80" height="30">
                <input id="upload" ref="upload" style="width:80px;height:30px; opacity:0;position:absolute;z-index:1" placeholder="" class=" cursor_pointer" type="file" accept=".xls,.xlsx" @change="fileChange">
                选择文件
              </v-btn>
            </div>
            <div class=" flex-y-center mt-3">
              点击
              <exportBtn :url="templateUrl" class="mx-2" :export-type="`template`" :parmas="{...parmas}" :title="`下载${frontName}导入模板`" :icon="`mdi-arrow-down-bold-box-outline`" />
              下载模板
            </div>
          </div>
        </div>
      </v-sheet>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" depressed height="30" @click="dialog.show = false">关闭</v-btn>
        <v-btn :disabled="!dialog.file.name" height="30" :loading="btnLoading" color="primary" width="100" depressed @click="sureUp">确定</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- <div>
    <v-btn :loading="btnLoading" small depressed style="" class=" mr-2 pa-0 primary--text" title="导入" width="32px" min-width="34" height="30">
      <input id="upload" ref="upload" style="width:34px;height:30px; opacity:0;position:absolute;z-index:1" placeholder="" class=" cursor_pointer" type="file" accept=".xls,.xlsx" @change="fileChange">
      <v-icon size="18" class=" cursor_pointer">
        mdi-import
      </v-icon>
    </v-btn>
  </div> -->
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    templateUrl: {
      type: String,
      default: ''
    },
    importUrl: {
      type: String,
      default: ''
    },
    parmas: {
      type: [Array, Object],
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    frontName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: {
        show: false,
        file: {},
        data: {}
      },
      btnLoading: false
    }
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.dialog.file = {}
        setTimeout(() => {
          this.$refs.upload.value = null
        }, 100)
      })
    },
    // 上传
    fileChange(e) {
      // const form = new FormData()
      // form.append('file', e.target.files[0])
      this.dialog.file = e.target.files[0]
      this.$refs.upload.value = null // 重复上传同一文件失效问题
    },
    // 确定上传
    sureUp() {
      const form = new FormData()
      const search = { ...this.parmas }
      if (this.$route.params.project_id) search.projectId = parseInt(this.$route.params.project_id)
      form.append('file', this.dialog.file)
      for (const i in search) {
        if (i === 'fieldString') {
          form.append(i, search[i].length ? JSON.stringify(search[i]) : '')
        } else {
          form.append(i, (search[i] || (search[i] === 0)) ? search[i] : '')
        }
      }
      // if (this.$route.params.project_id) form.append('projectId', parseInt(this.$route.params.project_id))
      this.btnLoading = true
      this.$http.post(this.importUrl, { data: form }).then(() => {
        this.$message.success('上传成功！')
        this.btnLoading = false
        this.$refs.upload.value = null // 重复上传同一文件失效问题
        this.dialog.show = false
        this.$emit('importOk')
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style>

</style>
