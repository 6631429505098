<!-- 项目信息 -->
<template>
  <div>
    <k-form-build :value="jsonData" ref="KFB" />
  </div>
</template>
<script>
export default {
  name: "Subject",
  data() {
    return {
      jsonData: {
        list: [
          {
            type: "card",
            label: "",
            list: [
                  {
                    type: "batch",
                    label: "动态表格",
                    icon: "icon-biaoge",
                    list: [
                      {
                        type: "input",
                        label: "受试者编号",
                        icon: "icon-write",
                        options: {
                          type: "text",
                          width: "100%",
                          defaultValue: "",
                          placeholder: "请输入",
                          clearable: false,
                          maxLength: null,
                          addonBefore: "",
                          addonAfter: "",
                          hidden: false,
                          disabled: false
                        },
                        model: "input_1638241923164",
                        key: "input_1638241923164",
                        help: "",
                        rules: [
                          {
                            required: false,
                            message: "必填项"
                          }
                        ]
                      },
                      {
                        type: "input",
                        label: "入组日期",
                        icon: "icon-write",
                        options: {
                          type: "text",
                          width: "100%",
                          defaultValue: "",
                          placeholder: "请输入",
                          clearable: false,
                          maxLength: null,
                          addonBefore: "",
                          addonAfter: "",
                          hidden: false,
                          disabled: false
                        },
                        model: "input_1638241937795",
                        key: "input_1638241937795",
                        help: "",
                        rules: [
                          {
                            required: false,
                            message: "必填项"
                          }
                        ]
                      },
                      {
                        type: "input",
                        label: "版本号",
                        icon: "icon-write",
                        options: {
                          type: "text",
                          width: "100%",
                          defaultValue: "",
                          placeholder: "请输入",
                          clearable: false,
                          maxLength: null,
                          addonBefore: "",
                          addonAfter: "",
                          hidden: false,
                          disabled: false
                        },
                        model: "input_1638241994277",
                        key: "input_1638241994277",
                        help: "",
                        rules: [
                          {
                            required: false,
                            message: "必填项"
                          }
                        ]
                      },
                      {
                        type: "input",
                        label: "状态",
                        icon: "icon-write",
                        options: {
                          type: "text",
                          width: "100%",
                          defaultValue: "",
                          placeholder: "请输入",
                          clearable: false,
                          maxLength: null,
                          addonBefore: "",
                          addonAfter: "",
                          hidden: false,
                          disabled: false
                        },
                        model: "input_1638242009182",
                        key: "input_1638242009182",
                        help: "",
                        rules: [
                          {
                            required: false,
                            message: "必填项"
                          }
                        ]
                      }
                    ],
                    options: {
                      scrollY: 0,
                      disabled: false,
                      hidden: false,
                      showLabel: false,
                      hideSequence: false,
                      width: "100%"
                    },
                    model: "batch_1638241913849",
                    key: "batch_1638241913849",
                    help: ""
                  }
                ],
            key: "card_1638242366190"
          }
        ],
        config: {
          layout: "vertical",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      }
    };
  },
  methods: {},
  mounted() {
      
      let _text={
            enrollmentTime: '入组时间',
            fields: null,
            id: null,
            partnerCid: null,
            partnerId: null,
            projectId: null,
            subjectCode: '受试者编号',
            subjectState: null,
            subjectStatus: '入组状态'

      }
      this.jsonData.list[0].list[0].list=[]
      this.$http.get(this.$api.formSubjectField,{data:''}).then((res)=>{
        // this.jsonData.list[0].list[1].list=[]
          if(res.result){
              let _num=0
              for(let i in res.result){
                  _num++
                  if(_text[i]){
                    let _id='input_'+parseInt(new Date().getTime()+_num)
                    let _a={
                            type: "input",
                            label: _text[i],
                            options: {
                                type: "text",
                                width: "100%",
                                defaultValue: "",
                                placeholder: "请输入",
                                clearable: false,
                                maxLength: null,
                                addonBefore: "",
                                addonAfter: "",
                                hidden: false,
                                disabled: false
                            },
                            model: _id,
                            key: _id,
                            help: "",
                            rules: [
                                {
                                required: false,
                                message: "必填项"
                                }
                            ]
                    }
                    this.jsonData.list[0].list[0].list.push(_a)
                  }
              }
          }
      })
  }
};
</script>
