// 引入自定义组件
// import HolidayComponent from './packages/custom/kit/HolidayComponent/'
// import DepartmentComponent from './packages/custom/kit/DepartmentComponent/'
// import PartnerComponent from './packages/custom/kit/PartnerComponent/'
// import ProjectComponent from './packages/custom/kit/ProjectComponent/'
// import UserComponent from './packages/custom/kit/UserComponent/'
// import DepartmentUserComponent from './packages/custom/kit/DepartmentUserComponent/'

// import OvertimeComponent from './packages/custom/kit/OvertimeComponent/'
// import ExtraShiftComponent from './packages/custom/kit/ExtraShiftComponent/'
// import BecomeFullComponent from './packages/custom/kit/BecomeFullComponent/'
// import ResignComponent from './packages/custom/kit/ResignComponent/'

// import ProjectInformation from './packages/custom/kit/ProjectInformation/'
// import Subject from './packages/custom/kit/Subject/'

import ProjectInformation1 from './packages/custom/kit/ProjectInformation1/'
import Subject1 from './packages/custom/kit/Subject1/'
import VisitField from './packages/custom/kit/VisitField/'
// import partnerField from './packages/custom/kit/partnerField/'
import ProtocolField from './packages/custom/kit/ProtocolField/'
import ProblemField from './packages/custom/kit/ProblemField/'
import AuditField from './packages/custom/kit/AuditField/'
import SaeField from './packages/custom/kit/SaeField/'


var controls = [
	{
		type: 'batch_form_project', // 组件类型
		label: '项目信息', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: ProjectInformation1, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_project', // 数据字段
		key: 'batch_form_project',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	// {
	// 	type: 'batch_form_site', // 组件类型
	// 	label: '中心信息', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: partnerField, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		// multiple: false, // 多选
	// 		// disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		// clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		// showSearch: false, // 可搜索
	// 		remark:false,
	// 		comment:false,
	// 	},
	// 	model: 'batch_form_site', // 数据字段
	// 	key: 'batch_form_site',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	{
		type: 'batch_form_sdv', // 组件类型
		label: 'SDV访视', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: VisitField, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_sdv', // 数据字段
		key: 'batch_form_sdv',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	{
		type: 'batch_form_protocol_violation', // 组件类型
		label: '方案违背', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: ProtocolField, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_protocol_violation', // 数据字段
		key: 'batch_form_protocol_violation',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	{
		type: 'batch_form_found_question', // 组件类型
		label: '问题发现', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: ProblemField, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_found_question', // 数据字段
		key: 'batch_form_found_question',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	{
		type: 'batch_form_sae', // 组件类型
		label: 'SAE', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: SaeField, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_sae', // 数据字段
		key: 'batch_form_sae',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	{
		type: 'batch_form_audit_discovery', // 组件类型
		label: '稽查发现', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: AuditField, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_audit_discovery', // 数据字段
		key: 'batch_form_audit_discovery',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	{
		type: 'batch_form_subject', // 组件类型
		label: '受试者', // 组件名称
		icon: 'icon-zidingyiyemian',
		component: Subject1, // 组件
		options: {
			defaultValue: undefined, // 默认值
			// multiple: false, // 多选
			// disabled: false, // 禁用
			width: '100%', // 宽度
			min: 0, // 最小值
			max: 99, // 最大值
			// clearable: true, // 可清除
			placeholder: '请选择', // 占位内容
			// showSearch: false, // 可搜索
			remark:false,
			comment:false,
		},
		model: 'batch_form_subject', // 数据字段
		key: 'batch_form_subject',
		rules: [ // 校验规则
			{
				required: false,
				message: '必填项'
			}
		]
	},
	
	
	
	
	
	// {
	// 	type: 'project-information', // 组件类型
	// 	label: '项目信息', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: ProjectInformation, // 组件
	// 	options: {
	// 		defaultValue: ['1','2','3','4','5','6','7','8'], // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false, // 可搜索
	// 		options: [
	// 			// 下拉选择项配置
	// 			{
	// 				value: "1",
	// 				label: "临床试验名称"
	// 			},
	// 			{
	// 				value: "2",
	// 				label: "方案编号"
	// 			},
	// 			{
	// 				value: "3",
	// 				label: "项目编号"
	// 			},
	// 			{
	// 				value: "4",
	// 				label: "适应症"
	// 			},
	// 			{
	// 				value: "5",
	// 				label: "预计入组人数"
	// 			},
	// 			{
	// 				value: "6",
	// 				label: "项目方案类型"
	// 			},
	// 			{
	// 				value: "7",
	// 				label: "试验类型"
	// 			},
	// 			{
	// 				value: "8",
	// 				label: "简介"
	// 			}
	// 		],
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'subject', // 组件类型
	// 	label: '受试者管理', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: Subject, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false, // 可搜索


	// 		// hidden: true, // 是否隐藏，false显示，true隐藏
	// 		// showLabel: false,
	// 		// hideSequence: false,
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'department', // 组件类型
	// 	label: '部门', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: DepartmentComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'department-user', // 组件类型
	// 	label: '部门与成员', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: DepartmentUserComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'project', // 组件类型
	// 	label: '项目', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: ProjectComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'partner', // 组件类型
	// 	label: '单位', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: PartnerComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
    // },
	// {
	// 	type: 'user', // 组件类型
	// 	label: '用户', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: UserComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// },
	// {
	// 	type: 'holiday', // 组件类型
	// 	label: '请假套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: HolidayComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
	// {
	// 	type: 'overtime', // 组件类型
	// 	label: '加班套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: OvertimeComponent, // 组件
	// 	options: {
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
	// {
	// 	type: 'extraShift', // 组件类型
	// 	label: '调休套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: ExtraShiftComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
	// {
	// 	type: 'becomeFull', // 组件类型
	// 	label: '转正套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: BecomeFullComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
	// {
	// 	type: 'resign', // 组件类型
	// 	label: '离职套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: ResignComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
	// {
	// 	type: 'demo', // 组件类型
	// 	label: '出差套件', // 组件名称
	// 	icon: 'icon-zidingyiyemian',
	// 	component: HolidayComponent, // 组件
	// 	options: {
	// 		defaultValue: undefined, // 默认值
	// 		multiple: false, // 多选
	// 		disabled: false, // 禁用
	// 		width: '100%', // 宽度
	// 		min: 0, // 最小值
	// 		max: 99, // 最大值
	// 		clearable: true, // 可清除
	// 		placeholder: '请选择', // 占位内容
	// 		showSearch: false // 可搜索
	// 	},
	// 	model: '', // 数据字段
	// 	key: '',
	// 	rules: [ // 校验规则
	// 		{
	// 			required: false,
	// 			message: '必填项'
	// 		}
	// 	]
	// }, 
];

export { controls };