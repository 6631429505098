<!-- 项目信息 -->
<template>
  <div>
    <k-form-build :value="jsonData" ref="KFB" />
  </div>
</template>
<script>
export default {
  name: "ProjectInformation",
  data() {
    return {
      jsonData: {
        list: [
          {
            type: "card",
            label: "",
            list: [
              // {
              //   type: "input",
              //   label: "项目名称",
              //   options: {
              //     type: "text",
              //     width: "100%",
              //     defaultValue: "",
              //     placeholder: "请输入",
              //     clearable: false,
              //     maxLength: null,
              //     addonBefore: "",
              //     addonAfter: "",
              //     hidden: false,
              //     disabled: false
              //   },
              //   model: "input_1638241810964",
              //   key: "input_1638241810964",
              //   help: "",
              //   rules: [
              //     {
              //       required: false,
              //       message: "必填项"
              //     }
              //   ]
              // },
              // {
              //   type: "input",
              //   label: "项目编号",
              //   options: {
              //     type: "text",
              //     width: "100%",
              //     defaultValue: "",
              //     placeholder: "请输入",
              //     clearable: false,
              //     maxLength: null,
              //     addonBefore: "",
              //     addonAfter: "",
              //     hidden: false,
              //     disabled: false
              //   },
              //   model: "input_1638241819883",
              //   key: "input_1638241819883",
              //   help: "",
              //   rules: [
              //     {
              //       required: false,
              //       message: "必填项"
              //     }
              //   ]
              // },
              // {
              //   type: "input",
              //   label: "方案编号",
              //   options: {
              //     type: "text",
              //     width: "100%",
              //     defaultValue: "",
              //     placeholder: "请输入",
              //     clearable: false,
              //     maxLength: null,
              //     addonBefore: "",
              //     addonAfter: "",
              //     hidden: false,
              //     disabled: false
              //   },
              //   model: "input_1638241826928",
              //   key: "input_1638241826928",
              //   help: "",
              //   rules: [
              //     {
              //       required: false,
              //       message: "必填项"
              //     }
              //   ]
              // },
              // {
              //   type: "textarea",
              //   label: "简介",
              //   icon: "icon-edit",
              //   options: {
              //     width: "100%",
              //     minRows: 4,
              //     maxRows: 6,
              //     maxLength: null,
              //     defaultValue: "",
              //     clearable: false,
              //     hidden: false,
              //     disabled: false,
              //     placeholder: "请输入"
              //   },
              //   model: "textarea_1638241840443",
              //   key: "textarea_1638241840443",
              //   help: "",
              //   rules: [
              //     {
              //       required: false,
              //       message: "必填项"
              //     }
              //   ]
              // }
            ],
            key: "card_1638242240324"
          }
        ],
        config: {
          layout: "vertical",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      }
    };
  },
  methods: {},
  mounted() {
    // let _text = {
    //   projectClass: "",
    //   projectClassName: "试验类型",
    //   projectDescribe: "描述",
    //   projectIndication: "适应症",
    //   projectManager: "",
    //   projectManagerName: "项目负责人",
    //   projectName: "项目名称",
    //   projectNumber: "项目编号",
    //   projectProtocolNumber: "试验编号"
    // };
    this.$http.get(this.$api.formProjectField, { data: "" }).then(res => {
      this.jsonData.list[0].list = [];
      const result = res.result || []
      if (result.length) {
        result.forEach(item => {
          const _id = item.fieldKey + parseInt(new Date().getTime());
          const _item = {
            type: "input",
            label: item.fieldName,
            options: {
              type: "text",
              width: "100%",
              defaultValue: "",
              placeholder: "请输入",
              clearable: false,
              maxLength: null,
              addonBefore: "",
              addonAfter: "",
              hidden: false,
              disabled: false
            },
            model: _id,
            key: _id,
            help: "",
            rules: [
              {
                required: false,
                message: "必填项"
              }
            ]
          }
          this.jsonData.list[0].list.push(_item)
        })
        // let _num = 0;
        // for (let i in res.result) {
        //   _num++;
        //   if (i.indexOf("project") != -1 && _text[i]) {
        //     let _id = "input_" + parseInt(new Date().getTime() + _num);
        //     let _a = {
        //       type: "input",
        //       label: _text[i],
        //       options: {
        //         type: "text",
        //         width: "100%",
        //         defaultValue: "",
        //         placeholder: "请输入",
        //         clearable: false,
        //         maxLength: null,
        //         addonBefore: "",
        //         addonAfter: "",
        //         hidden: false,
        //         disabled: false
        //       },
        //       model: _id,
        //       key: _id,
        //       help: "",
        //       rules: [
        //         {
        //           required: false,
        //           message: "必填项"
        //         }
        //       ]
        //     };
        //     this.jsonData.list[0].list.push(_a);
        //   }
        // }
      }
    });
  }
};
</script>
