<template>
  <div class="message-box" :class="direction">
    <div v-if="visible" :style="{'background-color':color}" class="message">
      <div class="message-content">{{ message }}</div>
      <!-- <div @click="close()">关了吧</div>
       <v-icon @click="close">close</v-icon> -->
    </div>

    <!-- <div
      :key="index"
      :style="{'background-color':item.color}"
      class="message"
      v-for="(item,index) in $store.state.messageList"
    >
      <div class="message-content">{{item.message}}</div>

    </div> -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      color: '',
      visible: true,
      list: '',
      message: '',
      closed: false,
      duration: '2000',
      timer: null,
      typeClass: '',
      icon: '',
      direction: 'bottom'

    }
  },
  watch: {
    closed(val) {
      if (val) {
        this.visible = false
        this.destroyElement()
        // this.$el.addEventListener('transitionend',this.destroyElement)
      }
    }
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    close() {
      this.closed = true
    },
    destroyElement() {
      // this.$el.removeEventListener('transitionend',this.destroyElement)
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close()
          }
        }, this.duration)
      }
    }

  }

}
</script>

<style lang="scss" scoped>
.message-box{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    width: 100%;
    /*background: rgba(0, 0, 0, .5);*/
    color: #ffffff;
    z-index: 1009;
    &.top {
        top: 65px;
    }

    &.bottom {
        bottom: 0;
    }

    &.center {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.top .message {
        animation: 0.35s toplight-frame-top;
    }
    &.bottom .message {
        animation: 0.35s toplight-frame;
    }

    .message {
        padding: 15px;
        margin-bottom: 15px;
        background-color: #1e88e5;
        border-radius: 4px;
        // box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        // 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
        color: #ffffff;
        opacity: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .message-content {
        flex: 1;
        letter-spacing: 1px;
        font-size: 14px;
        }

        .v-icon {
        flex-shrink: 0;
        color: #ffffff;
        }
    }
}
.message--success{
    background:#f0f9eb ;
    border-color:#e1f3d8 ;
    color: #67c23a;
}
.message--error{
    background:#ffccff ;
    border-color:#ffccff ;
    color: #cc0033;
}

@keyframes toplight-frame {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes toplight-frame-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
