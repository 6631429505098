<template>
  <div class="calendar">
    <div class="calendar-tools">
            <span @click="prev" class="calendar-prev">
                <svg height="20" version="1.1" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                <g class="transform-group">
                    <g transform="scale(0.015625, 0.015625)">
                        <path
                          d="M671.968 912c-12.288 0-24.576-4.672-33.952-14.048L286.048 545.984c-18.752-18.72-18.752-49.12 0-67.872l351.968-352c18.752-18.752 49.12-18.752 67.872 0 18.752 18.72 18.752 49.12 0 67.872l-318.016 318.048 318.016 318.016c18.752 18.752 18.752 49.12 0 67.872C696.544 907.328 684.256 912 671.968 912z"
                          fill="#5e7a88"></path>
                    </g>
                </g>
                </svg>
            </span>
      <div @click.stop="changeYear" class="calendar-info">
        <!-- {{monthString}} -->
        <div class="month">
          <div :style="{'top':-(this.month*20)+'px'}" class="month-inner">
            <span v-for="(m,index) in months" :key="index">{{m}}</span>
          </div>
        </div>
        <div class="year">{{year}}</div>
      </div>
      <span @click="next" class="calendar-next">
                <svg height="20" version="1.1" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                <g class="transform-group">
                    <g transform="scale(0.015625, 0.015625)">
                        <path
                          d="M761.056 532.128c0.512-0.992 1.344-1.824 1.792-2.848 8.8-18.304 5.92-40.704-9.664-55.424L399.936 139.744c-19.264-18.208-49.632-17.344-67.872 1.888-18.208 19.264-17.376 49.632 1.888 67.872l316.96 299.84-315.712 304.288c-19.072 18.4-19.648 48.768-1.248 67.872 9.408 9.792 21.984 14.688 34.56 14.688 12 0 24-4.48 33.312-13.44l350.048-337.376c0.672-0.672 0.928-1.6 1.6-2.304 0.512-0.48 1.056-0.832 1.568-1.344C757.76 538.88 759.2 535.392 761.056 532.128z"
                          fill="#5e7a88"></path>
                    </g>
                </g>
                </svg>
            </span>
    </div>
    <table cellpadding="5" class="calendar-table">
      <thead>
      <tr>
        <td class="week" v-for="week in weeks" :key="week">{{week}}</td>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(day,k1) in days"  :key="k1">
        <!-- ,'tips':$isDuringDate(child.date,tips[0],tips[1]) -->
        <td
          :class="{'selected':child.selected,'disabled':child.disabled,'first':child.first,'end':child.end}"
          @click="select(k1,k2,$event)"
          v-for="(child,k2) in day"  :key="'-'+k2">
          <a>
            <span>{{child.day}}</span>
            <div class="text" v-if="child.eventName!=undefined">{{child.eventName}}</div>
            <div :class="{'isLunarFestival':child.isLunarFestival,'isGregorianFestival':child.isGregorianFestival}"
                 class="text"
                 v-if="lunar">{{child.lunar}}
            </div>
          </a>

        </td>
      </tr>
      </tbody>
    </table>
    <div class="calendar-time" v-if="time&&!(range||multi)">
      <input class="flex-1 px-1" max="23" min="0" style="border: 1px solid #eee" type="number"
             v-model="hour"/>
      <div class="flex-shrink-0 mx-1">时</div>
      <input class="flex-1  px-1" max="59" min="0" style="border: 1px solid #eee" type="number"
             v-model="minute"/>
      <div class="flex-shrink-0 ml-1">分</div>
    </div>
    <div class="calendar-interval" v-if="interval&&!(range||multi||time)">
      <span class="flex-shrink-0 mr-1">时段：</span>
      <select v-model="intervalValue">
        <option value="上午">上午</option>
        <option value="下午">下午</option>
      </select>
    </div>
    <div class="calendar-button" v-if="(range||multi||time||interval)&&!hideButton">
      <span @click="setToday" class="cancel">Today</span>
      <div class="flex-y-center flex-1 flex-justify-end">
        <span @click="submit">确定</span>
        <span @click="cancel" class="cancel">重置</span>
      </div>
    </div>
    <div :class="{'show':yearsShow}" class="calendar-years">
      <a :class="{'active':y==year}" @click.stop="selectYear(y)" v-for="y in years" :key="y">{{y}}</a>
    </div>
    <v-dialog max-width="450" persistent v-model="confirm.show">
      <v-card>
        <v-card-title>时间提示</v-card-title>
        <form @submit.prevent="$emit('select', confirm.text),$emit('input', confirm.text),confirm.show=false">
          <div class="pa-4 text-body-2">
            <v-icon color="warning">mdi-information-outline</v-icon>
            您选择的时间超出建议范围，是否继续？
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="confirm.show=false,confirm.text=''" depressed min-width="100">取消
            </v-btn>
            <v-btn color="primary" depressed min-width="100"
                   type="submit">确定
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import calendar from './calendar.js'

  export default {
    props: {
      // 多选模式
      multi: {
        type: Boolean,
        default: false
      },
      // 范围模式
      range: {
        type: Boolean,
        default: false
      },
      //时间模式
      time: {
        type: Boolean,
        default: false
      },
      interval: {
        type: Boolean,
        default: false
      },
      // 默认日期
      value: {},
      hideButton: {
        type: Boolean,
        default: false
      },
      // 开始选择日期
      min: {
        type: String,
        default: ''
      },
      // 结束选择日期
      max: {
        type: String,
        default: ''
      },
      // 是否小于10补零
      zero: {
        type: Boolean,
        default: false
      },
      // 屏蔽的日期
      disabledDate: {
        type: Array,
        default: function() {
          return []
        }
      },
      // 是否显示农历
      lunar: {
        type: Boolean,
        default: false
      },
      // 时间格式
      format: {
        type: String,
        default: ''
      },
      // 自定义星期名称
      weeks: {
        type: Array,
        default: function() {
          return ['日', '一', '二', '三', '四', '五', '六']
        }
      },
      // 自定义月份
      months: {
        type: Array,
        default: function() {
          return ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
        }
      },
      // 自定义事件
      events: {
        type: Object,
        default: function() {
          return {}
        }
      },
      tips: {}
    },
    data() {
      return {
        myValue: [],
        show: false,
        years: [],
        yearsShow: false,
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        minute: 0,
        days: [],
        multiDays: [],
        today: [],
        intervalValue: null,
        festival: {
          lunar: {
            '1-1': '春节',
            '1-15': '元宵节',
            '2-2': '龙头节',
            '5-5': '端午节',
            '7-7': '七夕节',
            '7-15': '中元节',
            '8-15': '中秋节',
            '9-9': '重阳节',
            '10-1': '寒衣节',
            '10-15': '下元节',
            '12-8': '腊八节',
            '12-23': '祭灶节'
          },
          gregorian: {
            '1-1': '元旦',
            '2-14': '情人节',
            '3-8': '妇女节',
            '3-12': '植树节',
            '4-5': '清明节',
            '5-1': '劳动节',
            '5-4': '青年节',
            '6-1': '儿童节',
            '7-1': '建党节',
            '8-1': '建军节',
            '9-10': '教师节',
            '10-1': '国庆节',
            '12-24': '平安夜',
            '12-25': '圣诞节'
          }
        },
        begin: [],
        end: [],
        rangeBegin: [],
        rangeEnd: [],
        disabled: [],
        pageYear: 0,
        confirm: {
          show: false,
          text: ''
        }
      }
    },
    watch: {
      events() {
        this.render(this.year, this.month)
      },
      value() {
        this.init()
      },
      show() {
        this.yearsShow = false
      },
      hour(value) {
        if (value > 23) this.hour = 23
        if (value < 0) this.hour = 0
      },
      minute(value) {
        if (value > 59) this.minute = 59
        if (value < 0) this.minute = 0
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        let now = new Date()
        this.year = now.getFullYear()
        this.month = now.getMonth()
        this.day = now.getDate()
        this.hour = now.getHours()
        this.minute = now.getMinutes()


        if (this.min) {
          let begin = new Date(this.min)
          this.begin = [begin.getFullYear(), begin.getMonth() + 1, begin.getDate()]
        }
        if (this.max) {
          let end = new Date(this.max)
          this.end = [end.getFullYear(), end.getMonth() + 1, end.getDate()]
        }
        if (this.disabledDate.length) {
          this.disabledDate.forEach((e) => {
            let date = new Date(e)
            this.disabled.push([date.getFullYear(), date.getMonth() + 1, date.getDate()])
          })
        }
        if (this.range) { //范围
          this.myValue = [[now.getFullYear(), now.getMonth() + 1, now.getDate()], [now.getFullYear(), now.getMonth() + 1, now.getDate()]]
          this.value.forEach((e) => {
            now = new Date(e)
            this.myValue.push([now.getFullYear(), now.getMonth() + 1, now.getDate()])
          })
          this.year = parseInt(this.myValue[0][0])
          this.month = parseInt(this.myValue[0][1]) - 1
          this.day = parseInt(this.myValue[0][2])

          let year2 = parseInt(this.myValue[1][0])
          let month2 = parseInt(this.myValue[1][1]) - 1
          let day2 = parseInt(this.myValue[1][2])

          this.rangeBegin = [this.year, this.month, this.day]
          this.rangeEnd = [year2, month2, day2]
        } else if (this.multi) {//多选
          this.myValue = []
          this.value.forEach((e) => {
            now = new Date(e)
            this.myValue.push([now.getFullYear(), now.getMonth() + 1, now.getDate()])
          })
          this.multiDays = this.myValue
          this.year = parseInt(this.myValue[0][0])
          this.month = parseInt(this.myValue[0][1]) - 1
          this.day = parseInt(this.myValue[0][2])
        } else { //单选
          if (this.interval && this.value) {
            now = new Date(this.value.split(' ')[0])
            this.intervalValue = this.value.split(' ')[1]
          } else {
            now = this.value ? new Date(this.value) : new Date()
          }
          this.myValue = [now.getFullYear(), now.getMonth() + 1, now.getDate()]
          this.year = parseInt(this.myValue[0])
          this.month = parseInt(this.myValue[1]) - 1
          this.day = parseInt(this.myValue[2])

          if (this.time) {
            this.myValue[3] = now.getHours()
            this.myValue[4] = now.getMinutes()

            this.hour = this.zero ? this.zeroPad(this.myValue[3]) : this.myValue[3]
            this.minute = this.zero ? this.zeroPad(this.myValue[4]) : this.myValue[4]
          }
        }

        this.render(this.year, this.month)
      },
      // 渲染日期
      render(y, m) {
        let firstDayOfMonth = new Date(y, m, 1).getDay()         //当月第一天
        let lastDateOfMonth = new Date(y, m + 1, 0).getDate()    //当月最后一天
        let lastDayOfLastMonth = new Date(y, m, 0).getDate()     //最后一月的最后一天
        this.year = y
        let seletSplit = this.myValue
        let i, line = 0, temp = [], nextMonthPushDays = 1
        for (i = 1; i <= lastDateOfMonth; i++) {
          let day = new Date(y, m, i).getDay() //返回星期几（0～6）
          let k
          // 第一行
          if (day == 0) {
            temp[line] = []
          } else if (i == 1) {
            temp[line] = []
            k = lastDayOfLastMonth - firstDayOfMonth + 1
            for (let j = 0; j < firstDayOfMonth; j++) {
              // console.log("第一行",lunarYear,lunarMonth,lunarValue,lunarInfo)
              temp[line].push(Object.assign(
                { day: k, disabled: true, date: `${this.computedNextYear()}-${this.computedNextMonth(true)}-${k}` },
                this.getLunarInfo(this.computedPrevYear(), this.computedPrevMonth(true), k),
                this.getEvents(this.computedPrevYear(), this.computedPrevMonth(true), k)
              ))
              k++
            }
          }


          if (this.range) { // 范围
            // console.log("日期范围",this.getLunarInfo(this.year,this.month+1,i))
            let options = Object.assign(
              { day: i, date: `${this.year}-${this.month + 1}-${i}` },
              this.getLunarInfo(this.year, this.month + 1, i),
              this.getEvents(this.year, this.month + 1, i)
            )
            if (this.rangeBegin.length > 0) {
              let beginTime = Number(new Date(this.rangeBegin[0], this.rangeBegin[1], this.rangeBegin[2]))
              let endTime = Number(new Date(this.rangeEnd[0], this.rangeEnd[1], this.rangeEnd[2]))
              let stepTime = Number(new Date(this.year, this.month, i))
              if (beginTime === stepTime) {
                options.first = true
              }
              if (beginTime <= stepTime && endTime >= stepTime) {
                options.selected = true
              }
              if (endTime === stepTime) {
                options.end = true
              }
            }
            if (this.begin.length > 0) {
              let beginTime = Number(new Date(parseInt(this.begin[0]), parseInt(this.begin[1]) - 1, parseInt(this.begin[2])))
              if (beginTime > Number(new Date(this.year, this.month, i))) options.disabled = true
            }
            if (this.end.length > 0) {
              let endTime = Number(new Date(parseInt(this.end[0]), parseInt(this.end[1]) - 1, parseInt(this.end[2])))
              if (endTime < Number(new Date(this.year, this.month, i))) options.disabled = true
            }
            if (this.disabled.length > 0) {
              if (this.disabled.filter(v => {
                return this.year === v[0] && this.month === v[1] - 1 && i === v[2]
              }).length > 0) {
                options.disabled = true
              }
            }
            temp[line].push(options)
          } else if (this.multi) {//多选
            let options
            // 判断是否选中
            if (this.myValue.filter(v => {
              return this.year === v[0] && this.month === v[1] - 1 && i === v[2]
            }).length > 0) {
              options = Object.assign({
                day: i,
                selected: true, date: `${this.year}-${this.month + 1}-${i}`
              }, this.getLunarInfo(this.year, this.month + 1, i), this.getEvents(this.year, this.month + 1, i))
            } else {
              options = Object.assign({
                day: i,
                selected: false, date: `${this.year}-${this.month + 1}-${i}`
              }, this.getLunarInfo(this.year, this.month + 1, i), this.getEvents(this.year, this.month + 1, i))
              if (this.begin.length > 0) {
                let beginTime = Number(new Date(parseInt(this.begin[0]), parseInt(this.begin[1]) - 1, parseInt(this.begin[2])))
                if (beginTime > Number(new Date(this.year, this.month, i))) options.disabled = true
              }
              if (this.end.length > 0) {
                let endTime = Number(new Date(parseInt(this.end[0]), parseInt(this.end[1]) - 1, parseInt(this.end[2])))
                if (endTime < Number(new Date(this.year, this.month, i))) options.disabled = true
              }
              if (this.disabled.length > 0) {
                if (this.disabled.filter(v => {
                  return this.year === v[0] && this.month === v[1] - 1 && i === v[2]
                }).length > 0) {
                  options.disabled = true
                }
              }
            }

            temp[line].push(options)
          } else { // 单选
            // console.log(this.lunar(this.year,this.month,i));

            let chk = new Date()
            let chkY = chk.getFullYear()
            let chkM = chk.getMonth()
            // 匹配上次选中的日期
            if (parseInt(seletSplit[0]) == this.year && parseInt(seletSplit[1]) - 1 == this.month && parseInt(seletSplit[2]) == i) {
              // console.log("匹配上次选中的日期",lunarYear,lunarMonth,lunarValue,lunarInfo)
              temp[line].push(Object.assign(
                { day: i, selected: true, date: `${this.year}-${this.month + 1}-${i}` },
                this.getLunarInfo(this.year, this.month + 1, i),
                this.getEvents(this.year, this.month + 1, i)
              ))
              this.today = [line, temp[line].length - 1]
            }
            // 没有默认值的时候显示选中今天日期
            else if (chkY == this.year && chkM == this.month && i == this.day && this.myValue == '') {

              // console.log("今天",lunarYear,lunarMonth,lunarValue,lunarInfo)
              temp[line].push(Object.assign(
                { day: i, selected: true, date: `${this.year}-${this.month + 1}-${i}` },
                this.getLunarInfo(this.year, this.month + 1, i),
                this.getEvents(this.year, this.month + 1, i)
              ))
              this.today = [line, temp[line].length - 1]
            } else {
              // 普通日期
              // console.log("设置可选范围",i,lunarYear,lunarMonth,lunarValue,lunarInfo)
              let options = Object.assign(
                { day: i, selected: false, date: `${this.year}-${this.month + 1}-${i}` },
                this.getLunarInfo(this.year, this.month + 1, i),
                this.getEvents(this.year, this.month + 1, i)
              )
              if (this.begin.length > 0) {
                let beginTime = Number(new Date(parseInt(this.begin[0]), parseInt(this.begin[1]) - 1, parseInt(this.begin[2])))
                if (beginTime > Number(new Date(this.year, this.month, i))) options.disabled = true
              }
              if (this.end.length > 0) {
                let endTime = Number(new Date(parseInt(this.end[0]), parseInt(this.end[1]) - 1, parseInt(this.end[2])))
                if (endTime < Number(new Date(this.year, this.month, i))) options.disabled = true
              }
              if (this.disabled.length > 0) {
                if (this.disabled.filter(v => {
                  return this.year === v[0] && this.month === v[1] - 1 && i === v[2]
                }).length > 0) {
                  options.disabled = true
                }
              }
              temp[line].push(options)
            }
          }
          // 到周六换行
          if (day == 6 && i < lastDateOfMonth) {
            line++
          } else if (i == lastDateOfMonth) {
            // line++
            let k = 1
            for (let d = day; d < 6; d++) {
              // console.log(this.computedNextYear()+"-"+this.computedNextMonth(true)+"-"+k)
              temp[line].push(Object.assign(
                { day: k, disabled: true, date: `${this.computedNextYear()}-${this.computedNextMonth(true)}-${k}` },
                this.getLunarInfo(this.computedNextYear(), this.computedNextMonth(true), k),
                this.getEvents(this.computedNextYear(), this.computedNextMonth(true), k)
              ))
              k++
            }
            // 下个月除了补充的前几天开始的日期
            nextMonthPushDays = k
          }
        } //end for

        // console.log(this.year+"/"+this.month+"/"+this.day+":"+line)
        // 补充第六行让视觉稳定
        if (line <= 5 && nextMonthPushDays > 0) {

          // console.log({nextMonthPushDays:nextMonthPushDays,line:line})
          for (let i = line + 1; i <= 5; i++) {
            temp[i] = []
            let start = nextMonthPushDays + (i - line - 1) * 7
            for (let d = start; d <= start + 6; d++) {
              temp[i].push(Object.assign(
                { day: d, disabled: true, date: `${this.computedNextYear()}-${this.computedNextMonth(true)}-${d}` },
                this.getLunarInfo(this.computedNextYear(), this.computedNextMonth(true), d),
                this.getEvents(this.computedNextYear(), this.computedNextMonth(true), d)
              ))
            }
          }
        }
        this.days = temp
      },
      computedPrevYear() {
        let value = this.year
        if (this.month - 1 < 0) {
          value--
        }
        return value
      },
      computedPrevMonth(isString) {
        let value = this.month
        if (this.month - 1 < 0) {
          value = 11
        } else {
          value--
        }
        // 用于显示目的（一般月份是从0开始的）
        if (isString) {
          return value + 1
        }
        return value
      },
      computedNextYear() {
        let value = this.year
        if (this.month + 1 > 11) {
          value++
        }
        return value
      },
      computedNextMonth(isString) {
        let value = this.month
        if (this.month + 1 > 11) {
          value = 0
        } else {
          value++
        }
        // 用于显示目的（一般月份是从0开始的）
        if (isString) {
          return value + 1
        }
        return value
      },
      // 获取农历信息
      getLunarInfo(y, m, d) {
        let lunarInfo = calendar.solar2lunar(y, m, d)
        let lunarValue = lunarInfo.IDayCn
        // console.log(lunarInfo)
        let isLunarFestival = false
        let isGregorianFestival = false
        if (this.festival.lunar[lunarInfo.lMonth + '-' + lunarInfo.lDay] != undefined) {
          lunarValue = this.festival.lunar[lunarInfo.lMonth + '-' + lunarInfo.lDay]
          isLunarFestival = true
        } else if (this.festival.gregorian[m + '-' + d] != undefined) {
          lunarValue = this.festival.gregorian[m + '-' + d]
          isGregorianFestival = true
        }
        return {
          lunar: lunarValue,
          isLunarFestival: isLunarFestival,
          isGregorianFestival: isGregorianFestival
        }
      },
      // 获取自定义事件
      getEvents(y, m, d) {
        if (Object.keys(this.events).length == 0) return false
        let eventName = this.events[y + '-' + m + '-' + d]
        let data = {}
        if (eventName != undefined) {
          data.eventName = eventName
        }
        return data
      },
      // 上月
      prev(e) {
        e.stopPropagation()
        if (this.yearsShow) {
          this.pageYear--
          this.loadYear(this.pageYear)
        } else {
          if (this.month == 0) {
            this.month = 11
            this.year = parseInt(this.year) - 1
          } else {
            this.month = parseInt(this.month) - 1
          }
          this.render(this.year, this.month)
          this.$emit('selectMonth', this.month + 1, this.year)
          this.$emit('prev', this.month + 1, this.year)
        }
      },
      //  下月
      next(e) {
        e.stopPropagation()
        if (this.yearsShow) {
          this.pageYear++
          this.loadYear(this.pageYear)
        } else {
          if (this.month == 11) {
            this.month = 0
            this.year = parseInt(this.year) + 1
          } else {
            this.month = parseInt(this.month) + 1
          }
          this.render(this.year, this.month)
          this.$emit('selectMonth', this.month + 1, this.year)
          this.$emit('next', this.month + 1, this.year)
        }

      },
      // 选中日期
      select(k1, k2, e) {
        if (e != undefined) e.stopPropagation()
        // 日期范围
        if (this.range) {
          if (this.rangeBegin.length == 0 || this.rangeEndTemp != 0) {
            this.rangeBegin = [this.year, this.month, this.days[k1][k2].day]
            this.rangeBeginTemp = this.rangeBegin
            this.rangeEnd = [this.year, this.month, this.days[k1][k2].day]
            this.rangeEndTemp = 0

          } else {
            this.rangeEnd = [this.year, this.month, this.days[k1][k2].day]
            this.rangeEndTemp = 1
            // 判断结束日期小于开始日期则自动颠倒过来
            if (+new Date(this.rangeEnd[0], this.rangeEnd[1], this.rangeEnd[2]) < +new Date(this.rangeBegin[0], this.rangeBegin[1], this.rangeBegin[2])) {
              this.rangeBegin = this.rangeEnd
              this.rangeEnd = this.rangeBeginTemp
            }
            // 小于10左边打补丁
            let begin = []
            let end = []
            if (this.zero) {
              this.rangeBegin.forEach((v, k) => {
                if (k == 1) v = v + 1
                begin.push(this.zeroPad(v))
              })
              this.rangeEnd.forEach((v, k) => {
                if (k == 1) v = v + 1
                end.push(this.zeroPad(v))
              })
            } else {
              begin = this.rangeBegin
              end = this.rangeEnd
            }
            this.myValue = [begin, end]
            // console.log("选中日期",begin,end)
            // this.submit()
            // this.$emit('select', [begin, end])
          }
          this.render(this.year, this.month)
        } else if (this.multi) {
          // 如果已经选过则过滤掉
          let filterDay = this.multiDays.filter(v => {
            return this.year === v[0] && this.month === v[1] - 1 && this.days[k1][k2].day === v[2]
          })
          if (filterDay.length > 0) {
            this.multiDays = this.multiDays.filter(v => {
              return this.year !== v[0] || this.month !== v[1] - 1 || this.days[k1][k2].day !== v[2]
            })
          } else {
            this.multiDays.push([this.year, this.month + 1, this.days[k1][k2].day])
          }
          this.days[k1][k2].selected = !this.days[k1][k2].selected
          // this.submit()
          this.myValue = this.multiDays
          this.$emit('select', this.multiDays)
        } else {
          // 取消上次选中
          if (this.today.length > 0) {
            this.days.forEach(v => {
              v.forEach(vv => {
                vv.selected = false
              })
            })
          }
          // 设置当前选中天
          this.days[k1][k2].selected = true
          this.day = this.days[k1][k2].day
          this.today = [k1, k2]
          this.myValue = [
            this.year,
            this.zero ? this.zeroPad(this.month + 1) : this.month + 1,
            this.zero ? this.zeroPad(this.days[k1][k2].day) : this.days[k1][k2].day
          ]
          !this.time && !this.interval && this.submit()
          // console.log(1)
          // this.$emit('select', [this.year, this.zero ? this.zeroPad(this.month + 1) : this.month + 1, this.zero ? this.zeroPad(this.days[k1][k2].day) : this.days[k1][k2].day])
        }
      },
      changeYear() {
        if (this.yearsShow) {
          this.yearsShow = false
          return false
        }
        this.yearsShow = true
        this.loadYear(this.pageYear)
      },
      loadYear(page) {
        let size = 10
        let p = this.year + (page * size)

        this.years = []
        for (let i = ~~p - size; i < ~~p + size; i++) {
          this.years.push(i)
        }
      },
      selectYear(value) {
        this.yearsShow = false
        this.year = value
        this.render(this.year, this.month)
        this.$emit('selectYear', value)
      },
      // 返回今天
      setToday() {
        let now = new Date()
        this.year = now.getFullYear()
        this.month = now.getMonth()
        this.day = now.getDate()
        this.render(this.year, this.month)
        // 遍历当前日找到选中
        this.days.forEach(v => {
          let day = v.find(vv => {
            return vv.day == this.day && !vv.disabled
          })
          if (day != undefined) {
            day.selected = true
          }

        })
      },
      // 日期补零
      zeroPad(n) {
        n = parseInt(n)
        // && !(n.toString().indexOf('0') === 0)
        return (n < 10) && n.toString() !== '00' ? '0' + n : n
      },
      submit() {
        // let the = this
        let value = [...this.myValue]
        if (this.time) {
          value[3] = this.zero ? this.zeroPad(this.hour) : this.hour
          value[4] = this.zero ? this.zeroPad(this.minute) : this.minute
        }
        if (this.range) {
          this.$emit('select', [dateArr(value[0]), dateArr(value[1])])
          this.$emit('input', [dateArr(value[0]), dateArr(value[1])])
        } else if (this.multi) {
          // value.forEach((e) => {
          //   e = dateArr(e)
          // })
          this.$emit('select', [dateArr(value)])
          this.$emit('input', [dateArr(value)])
        } else {
          let text = dateArr(value)

          if (this.interval && this.intervalValue) {
            text += ' ' + this.intervalValue
          }
          if (this.tips && this.tips[0] && this.tips[1] && !this.$isDuringDate(text, this.tips[0], this.tips[1])) {
            this.confirm.text = text
            this.confirm.show = true
            return false
          }
          this.$emit('select', text)
          this.$emit('input', text)
        }

        function dateArr(arr) {
          let t = ''
          arr.forEach((item, index) => {
            if ([0, 1, 2].includes(index)) t += item + (index === 2 ? '' : '-')
            else if ([3, 4].includes(index)) {
              t += (index === 3 ? ' ' : '') + item + (index === 4 ? '' : ':')
            }
          })

          return t
        }
      },
      cancel() {
        if (this.range) { //范围
          this.myValue = [[], []]
        } else if (this.multi) {//多选
          this.myValue = []
        } else { //单选
          this.myValue = ''
        }
        this.$emit('select', this.myValue)
        this.$emit('input', this.myValue)
      },
      test(e) {
        console.log(e)
      }
    }
  }

</script>
<style lang="scss" scoped>

  $bg-color: #2196F3;
  .calendar {
    position: relative;

    .calendar-tools {
      padding: 0 15px;
      height: 40px;
      color: $bg-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .calendar-tools span {
      cursor: pointer;
    }

    .calendar-next,
    .calendar-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      /*text-align: center;*/
    }


    .calendar-info {
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    .calendar-info > div.month {
      margin: auto;
      height: 20px;
      width: 100px;
      text-align: center;
      color: $bg-color;
      overflow: hidden;
      position: relative;
    }

    .calendar-info > div.month .month-inner {
      position: absolute;
      left: 0;
      top: 0;
      height: 240px;
      transition: top .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .calendar-info > div.month .month-inner > span {
      display: block;
      font-size: 14px;
      height: 20px;
      width: 100px;
      overflow: hidden;
      text-align: center;
    }

    .calendar-info > div.year {
      font-size: 10px;
      line-height: 1;
      color: #999;
    }


    table.calendar-table {
      clear: both;
      width: 100%;
      border-collapse: collapse;
      color: #444444;

      td {
        margin: 2px !important;
        padding: 0px 0;
        width: 14.28571429%;
        height: 44px;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        vertical-align: top;

        &.tips a {
          background: #2196F3;
          color: #FFFFFF;
        }
      }

      td.week {
        font-size: 10px;
        pointer-events: none !important;
        cursor: default !important;
      }

      td.disabled {
        pointer-events: none !important;
        cursor: default !important;

        a {
          color: #ccc !important;
          background: #ffffff !important;
        }
      }

      td a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40px;
        height: 40px;
        color: #333333;
        margin: 0 auto;
        transition: all .35s ease;
        border-radius: 4px;
      }


      td:hover a,
      td.selected a {
        background-color: #E3F2FD;
        color: #666;
      }

      td.selected.first a,
      td.selected.end a {
        background-color: $bg-color;
        color: #fff;
      }

      td.selected.first a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      td.selected.end a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      td .text {
        font-size: 8px;
      }

      td .isGregorianFestival,
      td .isLunarFestival {
        color: #ea6151;
      }

      thead td {
        text-transform: uppercase;
        height: 30px;
        vertical-align: middle;
      }
    }

    table.calendar-table,
    .calendar-years {
      border-top: 1px dashed #eee;
      border-bottom: 1px dashed #eee;
    }

    .calendar-time {
      padding: 8px 15px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed #eee;
    }

    .calendar-interval {
      display: flex;
      align-items: center;
      padding: 5px 15px;
      border-bottom: 1px dashed #eee;

      select {
        border-radius: 4px;
        padding: 5px;
        width: 100%;
        border: 1px solid #EEEEEE;
      }
    }

    .calendar-years {
      position: absolute;
      left: 0;
      top: 40px;
      right: 0;
      bottom: 0;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow: auto;
      transition: all .35s ease;
      opacity: 0;
      pointer-events: none;
    }

    .calendar-years.show {
      opacity: 1;
      pointer-events: auto;
    }

    .calendar-years > a {
      margin: 1px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 40px;
      /*border: 1px solid #fbfbfb;*/
      color: #999;
      transition: all .35s ease;
    }

    .calendar-years > a:hover,
    .calendar-years > a.active {
      background-color: $bg-color;
      color: #fff;
    }

    .calendar-button {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        cursor: pointer;
        display: inline-block;
        background: $bg-color;
        color: #fff;
        font-size: 12px;
        margin-right: 5px;
        padding: 5px 20px;
        text-align: center;
        border-radius: .3em;
      }

      span.cancel {
        margin-right: 0;
        background: #efefef;
        color: #666;
      }
    }
  }

</style>
