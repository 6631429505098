import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from './api'
import axios from './axios'
import utils from './utils'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import draggable from 'vuedraggable'

import myMessage from '@/components/message/index.js'

import formItem from '@/components/formItem'// form表单input
import pagination from '@/components/pagination'// 分页组件
import calendar from '@/components/calendar'// 日历
import confirm from '@/components/confirm'// confirm二次确认弹窗
import exportBtn from '@/components/exportBtn' // 导出按钮
import importBtn from '@/components/importBtn' // 导入按钮
import listSort from '@/components/listSort' // 列表排序

import KFormDesign from '@/components/formDesign/packages'
import '@/components/formDesign/styles/form-design.less'

import { controls } from '@/components/formDesign/form-controls.js'
// 使用KFormDesign的setConfig函数添加自定义组件
KFormDesign.setConfig({
  title: '套件选择',
  list: controls
})

// import Vue from 'vue'
// import {
//   ConfigProvider,
//   Layout,
//   Input,
//   Rate,
//   Slider,
//   InputNumber,
//   Button,
//   Switch,
//   Radio,
//   Checkbox,
//   Select,
//   Card,
//   Form,
//   Row,
//   Col,
//   Modal,
//   Table,
//   Tabs,
//   Icon,
//   Steps,
//   Alert,
//   Tag,
//   Divider,
//   DatePicker,
//   TimePicker,
//   message,
//   Upload,
//   Tooltip,
//   FormModel,
//   Collapse,
//   Cascader,
//   TreeSelect
// } from 'ant-design-vue'

// Vue.use(TreeSelect)
// Vue.use(ConfigProvider)
// Vue.use(Cascader)
// Vue.use(Tooltip)
// Vue.use(FormModel)
// Vue.use(Collapse)
// Vue.use(Layout)
// Vue.use(Input)
// Vue.use(Rate)
// Vue.use(Slider)
// Vue.use(InputNumber)
// Vue.use(Button)
// Vue.use(Switch)
// Vue.use(Radio)
// Vue.use(Checkbox)
// Vue.use(Select)
// Vue.use(Card)
// Vue.use(Form)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Modal)
// Vue.use(Table)
// Vue.use(Tabs)
// Vue.use(Icon)
// Vue.use(Steps)
// Vue.use(Alert)
// Vue.use(Tag)
// Vue.use(Divider)
// Vue.use(DatePicker)
// Vue.use(TimePicker)
// Vue.use(Upload)
// // Vue.use(notification)
// // import 'ant-design-vue/dist/antd.css'

// Vue.prototype.$confirm = Modal.confirm
// Vue.prototype.$message = message

// import comment from '@/components/comment'//评论

// import tree from '@/components/tree'//树形
Vue.component('formItem', formItem)
Vue.component('pagination', pagination)
Vue.component('calendar', calendar)
Vue.prototype.$confirm = confirm
Vue.component('exportBtn', exportBtn)
Vue.component('importBtn', importBtn)
Vue.component('listSort', listSort)

Vue.use(KFormDesign)

// Vue.component('tree', tree)

// Vue.component('comment', comment)

import '@/assets/app.scss'
import '@/assets/main.scss'
import '@/assets/variables.scss'

Vue.config.productionTip = false

// Vue.use(api)
Vue.prototype.$api = api
Vue.prototype.$http = axios
Vue.use(utils)

Vue.use(Vuetify)
export default new Vuetify({
  icons: {
    iconfont: 'mdi' // 'md' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#2196f3',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#f1675d',
        success: '#52CCA3'
      }
    }
  }
  // themes: {
  //   light: {
  //     // 4682b4 1890ff
  //     primary: "#1890ff",
  //     secondary: "#b0bec5",
  //     accent: "#8c9eff",
  //     error: "#b71c1c",
  //   },
  // },
})

Vue.component('draggable', draggable)

Vue.prototype.$message = myMessage

// router.beforeEach((to,from,next)=>{
//   store.state.showMenu=to.meta.showMenu
//   console.log(to.path)
//   if(['/login','/register','/forgetPassword','/resetPassword','/form/category'].includes(to.path)){
//     next()
//   }else{
//     // if (!$cookies.get('access_token')) return next('/login')
//     if (!localStorage.getItem('access_token')) return next('/login')
//     next()
//   }
// })

window.vm_app = new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')
