import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar 进度条 路由守卫获取菜单时显示进度
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false }) // NProgress Configuration
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/',
    name: 'index',
    meta: {
      showMenu: true
    },
    component: () => import('@/views/index'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          icon: 'mdi-home-outline',
          showMenu: true
        },
        component: () => import('@/views/home')
      },
      {
        path: '/user',
        name: 'user',
        meta: {
          showMenu: true
        },
        component: () => import('@/views/auth/user')
      },
      {
        path: '/department',
        name: 'department',
        meta: {
          icon: 'mdi-lan',
          showMenu: true
        },
        component: () => import('@/views/department'),
        // redirect: '/department/user',
        children: [

          {
            path: '/department/roleList',
            name: 'roleList',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/department/roleList')
          },
          {
            path: '/department/users',
            name: 'departmentUsers',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/department/users')
          },
          {
            path: '/department/departmentList',
            name: 'departmentDepartmentList',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/department/departmentList')
          }
        ]

      },
      {
        path: '/property',
        name: 'property',
        meta: {
          icon: 'mdi-format-list-bulleted',
          showMenu: true
        },
        component: () => import('@/views/property')
      },

      {
        path: '/system',
        name: 'system',
        meta: {
          icon: 'mdi-shield-check-outline',
          showMenu: true
        },
        component: () => import('@/views/system'),
        redirect: '/system/email',
        children: [
          {
            path: '/system/projectLimit',
            name: 'systemProjectLimit',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/projectLimit')
          },
          {
            path: '/system/menu',
            name: 'systemMenu',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/menu')
          },
          {
            path: '/system/email',
            name: 'email',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/email')
          },
          {
            path: '/system/security',
            name: 'security',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/security')
          },
          {
            path: '/system/version',
            name: 'version',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/version')
          },
          {
            path: '/system/public',
            name: 'public',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/public')
          },
          {
            path: '/system/journal',
            name: 'journal',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/journal')
          },
          {
            path: '/system/association',
            name: 'association',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/association')
          },
          {
            path: '/system/task',
            name: 'systemtask',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/task')
          },
          {
            path: '/system/loginLog',
            name: 'systemloginLog',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/system/loginLog')
          }
        ]

      },
      // {
      //   path: '/form',
      //   name: 'form',
      //   meta: {
      //     showMenu: true
      //   },
      //   component: () => import('@/views/form'),
      //   redirect: '/form/category',
      //   children: [
      //     {
      //       path: '/form/category',
      //       name: 'category',
      //       meta: {
      //         showMenu: true
      //       },
      //       component: () => import('@/views/form/category'),
      //     },
      //   ]

      // },
      {
        path: '/reportConfigure',
        name: 'reportConfigure',
        meta: {
          icon: 'mdi-clipboard-text-outline',
          showMenu: true
        },
        component: () => import('@/views/reportConfigure')
      },
      {
        path: '/project',
        name: 'project',
        meta: {
          icon: 'mdi-view-dashboard-outline',
          showMenu: true
        },
        component: () => import('@/views/project'),
        children: [

          {
            path: '/project/folder',
            name: 'folder',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/folder')
          },
          {
            path: '/project/progress',
            name: 'progress',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/progress')
          },
          {
            path: '/project/template',
            name: 'template',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/template')
          },
          {
            path: '/project/node',
            name: 'node',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/node')
          },
          {
            path: '/project/projectClass',
            name: 'projectClass',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/projectClass')
          },
          {
            path: '/project/reportStoragePath',
            name: 'reportStoragePath',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/reportStoragePath')
          },
          {
            path: '/project/projectVisitBaseline',
            name: 'projectVisitBaseline',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/projectVisitBaseline')
          },
          {
            path: '/project/autoNumber',
            name: 'autoNumber',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/autoNumber')
          },
          {
            path: '/project/bonus',
            name: 'projectBonus',
            meta: {
              showMenu: true
            },
            component: () => import('@/views/project/bonus')
          }
        ]

      },
      {
        path: '/hour',
        name: 'hour',
        meta: {
          icon: 'mdi-calendar-blank',
          showMenu: true
        },
        component: () => import('@/views/hour')
      },
      {
        path: '/version',
        name: 'version',
        meta: {
          showMenu: false
        },
        component: () => import('@/views/version')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      showMenu: false
    },
    component: () => import('@/views/auth/login')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: {
      showMenu: false
    },
    component: () => import('@/views/auth/resetPassword')
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    meta: {
      showMenu: false
    },
    component: () => import('@/views/auth/forgetPassword')
  }
]

const router = new VueRouter({
  routes
})

let first = true // 是否需要获取菜单的标志
router.beforeEach((to, from, next) => {
  store.state.showMenu = to.meta.showMenu
  if (first && !['/login', '/register', '/forgetPassword', '/resetPassword', '/form/category'].includes(to.path)) {
    first = false
    if (localStorage.getItem('access_token')) {
      NProgress.start()
      store.dispatch('getMenu').then(() => { // 首次加载或重新登录时，重新获取菜单
        NProgress.done()
        next()
      }).catch(() => {
        NProgress.done()
        // first = true
        // next()
        next()
      })
    } else {
      first = true
      return next('/login')
    }
  } else {
    if (['/login'].includes(to.path)) {
      if (localStorage.getItem('access_token')) { // 去登录页但是有access_token时，跳到首页
        next({ path: '/home' })
      } else {
        first = true
        next()
      }
    } else {
      next()
    }
  }
})

export default router
