// import Vue from 'vue'
import axios from 'axios'
// import $cookies from 'js-cookie'
import router from '../router'
import message from '@/components/message/index.js'

const service = axios.create({
  baseURL: '/'
})
const getParam = (data) => {
  let param = (data && data.data) || null
  if (data && data.type && data.data) {
    param = new URLSearchParams()
    for (const i in data.data) {
      // param.append(i, data.data[i]||'')
      param.append(i, (data.data[i] || data.data[i] === 0) ? data.data[i] : '')
    }
  }
  return param
}
const $http = {
  post(url, data) {
    return new Promise((resolve, reject) => {
      service.post(url, getParam(data)).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error.data)
      })
      // if (data.type) {
      //   service({
      //     url,
      //     data: data.data || null,
      //     method: 'post',
      //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      //   }).then(res => {
      //     resolve(res.data)
      //   }).catch(error => {
      //     reject(error.data)
      //   })
      // } else {
      //   service({
      //     url,
      //     data: data.data || null,
      //     method: 'post'
      //   }).then(res => {
      //     resolve(res.data)
      //   }).catch(error => {
      //     reject(error.data)
      //   })
      // }
    })
  },
  get(url, data) {
    return new Promise((resolve, reject) => {
      service.get(url, { params: getParam(data) }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error.data)
      })
    })
  },
  delete(url, data) {
    return new Promise((resolve, reject) => {
      service.delete(url, { params: data.data || null }).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  put(url, data) {
    return new Promise((resolve, reject) => {
      service.put(url, getParam(data)).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error.data)
      })
    })
  },
  blobGet(url, data) {
    return new Promise((resolve, reject) => {
      service({
        url,
        params: data.data || null,
        method: 'get',
        responseType: 'blob'
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
service.interceptors.request.use(config => {
  // if(config.method=='post'){

  // }
  // config.headers['X-Access-Token'] = $cookies.get('access_token')||''
  // config.headers['X-Access-Token'] = localStorage.getItem('access_token') || ''
  if (localStorage.getItem('access_token')) {
    config.headers['X-Access-Token'] = localStorage.getItem('access_token') || ''
  } else {
    // 除了这几个接口外，没有token 跳去登录页
    if (config.url.indexOf('/sys/randomImage') > -1 || config.url.indexOf('/sys/login') > -1 ||
    config.url.indexOf('/sys/sendCaptchaToMail') > -1 || config.url.indexOf('/sys/checkPasswordCaptcha') > -1 ||
    config.url.indexOf('/sys/resetPassword') > -1 || config.url.indexOf('/sys/changePassword') > -1) {
      //
    } else {
      router.replace({ path: '/login' })
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})
service.interceptors.response.use((response) => {
  if (response.config.responseType) return Promise.resolve(response) // 下载文件
  response.data.code = parseInt(response.data.code)
  // response.status 200
  // if (response.data.code === 200){
  //     // return response.data
  //     return Promise.resolve(response)
  // }else if (response.data.code === 500) {
  //     return Promise.reject(response)
  // }else if(response.data.code===510){
  //     router.replace({path:'/login'})
  //     return Promise.reject(response)
  // }

  if (response.data.code === 0 || response.data.code === 200) {
    return Promise.resolve(response)
  } else if ([406, 407, 408].includes(response.data.code)) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('userInfo')
    message.error(response.data.message || '请稍后重试')
    router.replace({ path: '/login' })
    return Promise.reject(response)
  } else {
    // 上传模板不用弹消息
    // if (response.config.url !== 'ctms/backend/folderTemplate/fileTemplateUpload')
    message.error(response.data.message)
    // 登录接口返回501首次登录，要修改密码 在登录页判断了
    // if(response.data.code === 500){
    //     router.replace({path:'/login'})
    // }
    return Promise.reject(response)
  }
}, (error) => {
  // localStorage.removeItem('access_token')
  // message.error(error.response.data.message || '请稍后重试')
  // router.replace({ path: '/login' })
  // return Promise.reject(error.response)
  if (error.response.status) {
    switch (error.response.status) {
      case 401:
        break
      case 404:
        message.error(error.response.data.message || '很抱歉，资源未找到!')
        break
      case 406:
        localStorage.removeItem('access_token')
        localStorage.removeItem('userInfo')
        message.error(error.response.data.message || '请稍后重试')
        router.replace({ path: '/login' })
        break
      case 500:
        message.error(error.response.data.message || '请稍后重试')
        break
      default:
        message.error(error.response.data.message || '请稍后重试')
        break
    }
    return Promise.reject(error.response)
  } else {
    message.error(error.response.data.message || '请稍后重试')
  }
})
export default $http
