import Vue from 'vue'

Vue.directive('frozenColumn', {
  inserted(e) {
    e.style.position = 'sticky'
    e.style.zIndex = '2'
    e.className = 'sticky'
    const $list = []
    let value = 0

    e.setAttribute('frozen', 'true')
    e.parentNode.childNodes.forEach((item) => {
      if ((item.nodeName === 'TH' || item.nodeName === 'TD') && item.getAttribute('frozen')) {
        $list.push(item)
      }
    })
    $list.forEach((item, index) => {
      // item.style.borderRight = '0';
      if (index) {
        for (let i = 0; i < index; i++) {
          value += $list[i].clientWidth
        }
      }

      e.style.left = value + 'px'
      // if ((index + 1) === $list.length) {
      //   item.style.borderRight = '1px solid rgba(0, 0, 0, 0.12)';
      // }
      value = 0
    })
  },
  //   update(e, binding, vnode, oldVnode) {
  update() {
    // console.log(e.clientWidth)
  },
  //   componentUpdated(e, binding, vnode, oldVnode) {
  componentUpdated() {
    // if (oldVnode.isRootInsert) {
    // }
  }
})
export default {
  install(vue) {
    vue.prototype.$isDuringDate = function(current, start, end) { // 判断时间是否在指定范围
      const curDate = new Date(current)
      const beginDate = new Date(start)
      const endDate = new Date(end)
      beginDate.setDate(beginDate.getDate() - 1)
      // endDate.setDate(endDate.getDate() + 1)
      if (curDate >= beginDate && curDate <= endDate) {
        return true
      }
      return false
    }
    vue.prototype.$deepClone = function(obj) { // 深拷贝
      return JSON.parse(JSON.stringify(obj))
    }
    vue.prototype.$formatTime = function(fmt, date) {
      //     // yyyy-MM-dd HH:mm:ss
      const crtTime = new Date(date)
      const o = {
        'M+': crtTime.getMonth() + 1, // 月份
        'd+': crtTime.getDate(), // 日
        'h+': crtTime.getHours(), // 小时
        'm+': crtTime.getMinutes(), // 分
        's+': crtTime.getSeconds(), // 秒
        'q+': Math.floor((crtTime.getMonth() + 3) / 3), // 季度
        'S': crtTime.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (crtTime.getFullYear() + '').substr(4 - RegExp.$1.length)) }
      for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
      }
      return fmt
    }
  }
}
// export default function({ app }, inject) {
//   inject('formatTime', (fmt, date) => {
//     // yyyy-MM-dd HH:mm:ss
//     let crtTime = new Date(date)

//     let o = {
//       'M+': crtTime.getMonth() + 1,                 //月份
//       'd+': crtTime.getDate(),                    //日
//       'h+': crtTime.getHours(),                   //小时
//       'm+': crtTime.getMinutes(),                 //分
//       's+': crtTime.getSeconds(),                 //秒
//       'q+': Math.floor((crtTime.getMonth() + 3) / 3), //季度
//       'S': crtTime.getMilliseconds()             //毫秒
//     }
//     if (/(y+)/.test(fmt))
//       fmt = fmt.replace(RegExp.$1, (crtTime.getFullYear() + '').substr(4 - RegExp.$1.length))
//     for (let k in o)
//       if (new RegExp('(' + k + ')').test(fmt))
//         fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
//     return fmt
//   })
//   inject('jsonLength', (list) => {
//     let i = 0
//     for (let k in list) {
//       i++
//     }
//     return i
//   })
//   inject('arrayRemove', (arr, val) => {
//     let index = arr.indexOf(val)
//     if (index > -1) {
//       arr.splice(index, 1)
//     }
//     return arr
//   })
//   inject('delHtmlTag', (str) => {
//     return str && str.replace(/<[^>]+>/g, '')//去掉所有的html标记
//   })
// }
// export default function({ app }, inject) {
//     export default function(inject) {
//     inject('formatTime', (fmt, date) => {
//       // yyyy-MM-dd HH:mm:ss
//       let crtTime = new Date(date)

//       let o = {
//         'M+': crtTime.getMonth() + 1,                 //月份
//         'd+': crtTime.getDate(),                    //日
//         'h+': crtTime.getHours(),                   //小时
//         'm+': crtTime.getMinutes(),                 //分
//         's+': crtTime.getSeconds(),                 //秒
//         'q+': Math.floor((crtTime.getMonth() + 3) / 3), //季度
//         'S': crtTime.getMilliseconds()             //毫秒
//       }
//       if (/(y+)/.test(fmt))
//         fmt = fmt.replace(RegExp.$1, (crtTime.getFullYear() + '').substr(4 - RegExp.$1.length))
//       for (let k in o)
//         if (new RegExp('(' + k + ')').test(fmt))
//           fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
//       return fmt
//     })
//     inject('jsonLength', (list) => {
//       let i = 0
//       for (let k in list) {  //eslint-disable-line no-unused-vars
//         i++
//       }
//       return i
//     })
//     inject('arrayRemove', (arr, val) => {
//       let index = arr.indexOf(val)
//       if (index > -1) {
//         arr.splice(index, 1)
//       }
//       return arr
//     })
//     inject('delHtmlTag', (str) => {
//       return str && str.replace(/<[^>]+>/g, '')//去掉所有的html标记
//     })
//   }
